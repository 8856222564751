import { initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';

class FirebaseProvider {
  constructor(config) {
    if (!config) {
      throw new Error('Firebase configuration is required.');
    }

    try {
      // Initialize Firebase app and authentication
      this.app = initializeApp(config);
      this.auth = getAuth(this.app);
      setPersistence(this.auth, browserLocalPersistence);

      console.log('FirebaseProvider initialized successfully.');
    } catch (error) {
      console.error('Error initializing FirebaseProvider:', error);
      throw new Error('Failed to initialize FirebaseProvider.');
    }
  }

  async login({ email, password }) {
    try {
      const response = await signInWithEmailAndPassword(this.auth, email.toLowerCase(), password);
      return { user: response.user };
    } catch (error) {
      alert('username or password are invalid');
      console.error('Login failed:', error);
      throw new Error('Invalid credentials or login issue.');
    }
  }

  async register({ email, password }) {
    try {
      const response = await createUserWithEmailAndPassword(this.auth, email, password);
      return { user: response.user };
    } catch (error) {
      console.error('Registration failed:', error);
      throw new Error('Registration failed. Check email and password.');
    }
  }

  async fetchSignInMethods(email) {
    try {
      const methods = await fetchSignInMethodsForEmail(this.auth, email);
      return methods;
    } catch (error) {
      console.error('Failed to fetch sign-in methods:', error);
      throw new Error('Could not retrieve sign-in methods.');
    }
  }

  getAuthInstance() {
    if (!this.auth) {
      throw new Error('FirebaseProvider is not properly initialized.');
    }
    return this.auth;
  }
}

export default FirebaseProvider;
