import React, { useMemo, memo } from "react";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import "./TooltipModel.scoped.css";

// 1) Create a custom-styled Material-UI tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "white",
    color: "#333",
    fontSize: "14px",
    padding: "20px",
    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    maxWidth: "420px",
    lineHeight: "1.6",
  },
});

// 2) Create the content displayed inside the Tooltip
const TooltipContent = memo(({ option }) => {
  if (!option?.tooltip) return null;

  const { tooltip } = option;

  return (
    <div>
      <div className="tooltip-title">{option.displayName}</div>
      <div className="tooltip-subtitle">
        {tooltip.developer} • {tooltip.modelType}
      </div>
      <hr className="tooltip-separator" />
      <div className="tooltip-content">
        <div className="section">
          <div className="section-label">Input / Output:</div>
          <div className="section-value">
            Input: {tooltip.input}
            <br />
            Output: {tooltip.output}
          </div>
        </div>
        <hr className="tooltip-separator" />
        <div className="grid-section">
          <div className="grid-item">
            <div className="section-label">Best for:</div>
            <div className="section-value">{tooltip.bestFor}</div>
          </div>
          <div className="vertical-separator" />
          <div className="grid-item">
            <div className="section-label">Use case:</div>
            <div className="section-value">{tooltip.useCase}</div>
          </div>
        </div>
        {tooltip.pricing && (
          <>
            <hr className="tooltip-separator" />
            <div className="section">
              <div className="section-label">Pricing:</div>
              <div className="pricing-section">
                {tooltip.pricing.input && (
                  <div className="pricing-item">
                    Input: {tooltip.pricing.input}
                  </div>
                )}
                {tooltip.pricing.output && (
                  <div className="pricing-item">
                    Output: {tooltip.pricing.output}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {tooltip.knowledgeCutoff && (
          <>
            <hr className="tooltip-separator" />
            <div className="section">
              <div className="section-label">Knowledge cutoff:</div>
              <div className="section-value">{tooltip.knowledgeCutoff}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

TooltipContent.displayName = "TooltipContent";

// 3) Wrap the target children with our custom tooltip
const TooltipModel = memo(({ children, option }) => {
  const tooltipContent = useMemo(() => <TooltipContent option={option} />, [
    option,
  ]);

  return (
    <CustomTooltip
      title={tooltipContent}
      placement="right"
      arrow
      PopperProps={{
        sx: {
          "& .MuiTooltip-arrow": {
            color: "white",
          },
        },
      }}
    >
      {children}
    </CustomTooltip>
  );
});

TooltipModel.displayName = "TooltipModel";

export default TooltipModel;