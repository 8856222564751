import './UploadStatus.css'
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import { ReactComponent as UploadIcon } from '@/assets/upload-icon.svg'
import { ReactComponent as ExpandIcon } from '@/assets/expand-upload-status.svg'
import { ReactComponent as CollapseIcon } from '@/assets/collapse-upload-status.svg'
import { uploadStatusPlacementAtom, isExpandedAtom, uploadedDocsAtom } from '@/atoms/upload'
import { useAtom } from 'jotai'
import StatusIcon from './StatusIcon/StatusIcon'
import pdfImg from '@/assets/pdf-img.png'
import wordImg from '@/assets/word-img.png'
import { propertiesAtom, socketIOService, userAtom } from '../../atoms/general'
import createFileQueueService, { PROCESSING_EVENT_TYPE, PROCESSING_FILE_STATUS, UPLOAD_PROGRESS_EVENT } from '../../services/fileQueueService'
import { treeAtom, pathAtom } from '@/atoms/folders'
import { useSnackbar } from 'notistack'
import Snackbar from '@/components/Snackbar/Snackbar'
import { addFileToFolder } from '../../services/files/addFile'
import { getOngoingDocumentProcessing } from '../../api/upload'
import createStorageFactoryService from '../../services/storageService/storageFactoryService'

const API_TASK_STATUS = {
    PENDING: 'PENDING',
    PROCESSING: 'PROCESSING',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
}

const TASK_STATUS_MAP = {
    [API_TASK_STATUS.PENDING]: PROCESSING_FILE_STATUS.LOADING,
    [API_TASK_STATUS.PROCESSING]: PROCESSING_FILE_STATUS.LOADING,
    [API_TASK_STATUS.COMPLETED]: PROCESSING_FILE_STATUS.SUCCESS,
    [API_TASK_STATUS.FAILED]: PROCESSING_FILE_STATUS.FAIL,
}

function UploadStatus({ onClose }) {
    const [isExpanded, setIsExpanded] = useAtom(isExpandedAtom)
    const [uploadFilesList, setUploadFilesList] = useAtom(uploadedDocsAtom)
    const [properties] = useAtom(propertiesAtom)
    const [isDragging, setIsDragging] = useState(false)
    const [blobUrl, setBlobUrl] = useState('')
    const [position, setPosition] = useAtom(uploadStatusPlacementAtom)
    const [entireTree, setEntireTree] = useAtom(treeAtom)
    const { enqueueSnackbar } = useSnackbar()
    const [user] = useAtom(userAtom)
    const [path] = useAtom(pathAtom)

    const dragRef = useRef(null)
    const [minDragWidth, setMinDragWidth] = useState(-window.innerWidth * 0.76)
    const maxDragWidth = 0
    // const minDragWidth = 0
    const [ uploadHeaderText, setUploadHeaderText ] = useState('Upload file to start')
    useMemo(() => {
        setBlobUrl(properties.urls?.blob_url)
    }, [properties])


    const storageFactoryService = createStorageFactoryService();

    const filesQueueService = createFileQueueService(storageFactoryService);


    const getProcesingProgressPrecentage = useCallback((progress) => {
        const eventProgressMap = {
            [PROCESSING_EVENT_TYPE.PREPARE]: 25,
            [PROCESSING_EVENT_TYPE.START]: 25,
            [PROCESSING_EVENT_TYPE.DOWNLOAD]: 40,
            [PROCESSING_EVENT_TYPE.Q_AND_A]: 50,
            [PROCESSING_EVENT_TYPE.SAVE_DB]: 60,
            [PROCESSING_EVENT_TYPE.SAVE_VECTORS]: 80,
            [PROCESSING_EVENT_TYPE.COMPLETED]: 100,
        }
        return eventProgressMap[progress] || 25
    }, [])

    const updateFilesList = useCallback(() => {
        const queue = filesQueueService.getQueue()
        setUploadFilesList([...queue])
    }, [filesQueueService, setUploadFilesList])

    const removeFileFromQueue = useCallback((index) => {
        filesQueueService.removeFileByIndex(index)
        const queue = filesQueueService.getQueue()
        setUploadFilesList([...queue])
    }, [filesQueueService, setUploadFilesList])

    const processNextFile = useCallback(async () => {
        if (filesQueueService.getIsProcessing()) {
            console.warn(`File processing already in progress`)
            return null
        }
        console.log(`Starting next file processing...`)
        return await filesQueueService.documentProcessingPipeline({ user, blobUrl, path })
    }, [filesQueueService, user, blobUrl, path])


    const handleDocumentEvents = useCallback(
        (event) => {
            const { type, resourceId, status, progress } = event
            const queue = filesQueueService.getQueue()
            let currentFileId = ''
            const isSuccessEvent = status === PROCESSING_FILE_STATUS.SUCCESS
            const isCompletedEvent = type === PROCESSING_EVENT_TYPE.COMPLETED
            const updatedList = queue.map((file) => {
                if (!file.mediaKey || (file.finished && [PROCESSING_FILE_STATUS.SUCCESS, PROCESSING_FILE_STATUS.FAIL].includes(file.status))) return file

                const isEventFile = resourceId === file.mediaKey
                const fileFinished = file.finished === true || (isCompletedEvent && isEventFile)
                const statusUpdate = isEventFile
                    ? filesQueueService.setFileStatus(event)
                    : filesQueueService.getFileStatus(file.mediaKey)
                if (isEventFile) {
                    console.log(`Received event: ${type} for file ${resourceId}, status update: ${statusUpdate}`)
                    currentFileId = resourceId
                }
                const progressUpdate = type === PROCESSING_FILE_STATUS.LOADING ? progress : getProcesingProgressPrecentage(type)
                console.log(`Progress data for ${type}: ${progressUpdate}%`)
                return {
                    ...file,
                    finished: fileFinished,
                    progress: progressUpdate,
                    status: statusUpdate,
                    name: file.name,
                    id: file.id,
                }
            })

            if (isCompletedEvent && isSuccessEvent && currentFileId) {
                // Adding file to tree
                const fileData = filesQueueService.getFileFolderTreeDisplayData(currentFileId)
                const folderId = fileData ? fileData.folder_id : null
                console.log(
                    `File ${resourceId} processing success adding to files tree folderId: ${folderId ? folderId : 'root'}`
                )

                const newTree = addFileToFolder(folderId, fileData, entireTree)
                setEntireTree(newTree)
            }

            if (isCompletedEvent) {
                console.log(`File ${resourceId} processing completed with status: ${status}`)

                const completedFiles = updatedList.filter((file) => file.finished === true)
                console.log(`Completed ${completedFiles.length} files out of ${queue.length}`)
                filesQueueService.setFinishedCurrentFile()

                if (completedFiles.length > 0 && completedFiles.length === queue.length) {
                    const successfulFiles = completedFiles.filter((file) => file.status === 'success')
                    enqueueSnackbar(`Processed ${successfulFiles.length} out of ${queue.length}`, {
                        content: (key, message) => <Snackbar type={'success'} message={message} />,
                    })

                    setIsExpanded(false)
                }

                setUploadFilesList([...updatedList])
                processNextFile()
                return
            }
            return setUploadFilesList([...updatedList])
        },
        [setUploadFilesList, processNextFile, filesQueueService, enqueueSnackbar, entireTree, setEntireTree, getProcesingProgressPrecentage]
    )

    const handleCustomEvent = useCallback((event) => {
        const { detail } = event
        const { resourceId, progress, status = PROCESSING_FILE_STATUS.LOADING } = detail

        const payload = { type: PROCESSING_FILE_STATUS.LOADING, resourceId, status, progress }
        console.log(`Received custom event:`, payload)
        handleDocumentEvents(payload)
    }, [handleDocumentEvents])

    const requestOngoingDocumentProcessing = useCallback(async () => {
        try {
            console.log(`Requesting ongoing document processing...`)
            const processingArr = await getOngoingDocumentProcessing()
            const mapped = processingArr.map((process) => {
                const { payload, status } = process
                const { docTitle, mediaKey, size, folderId } = payload
                const uploadData = {
                    chunkSize: process.chunkSizeFromUser,
                    chunkOverLap: process.chunkSizeFromUser,
                    embeddingModel: process.embeddingModel,
                    vectorLanguage: process.vectorLanguage,

                }
                const data = {
                    vectorMethods: payload.vectorMethods,
                    uploadData
                }

                const statusUpdate = TASK_STATUS_MAP[status] || PROCESSING_FILE_STATUS.LOADING

                return {
                    data,
                    mediaKey: mediaKey,
                    name: docTitle,
                    size: size,
                    status: statusUpdate,
                    finished: false,
                    config: {
                        folderId,
                    },
                    folderTreeDisplayData: {
                        created_at: new Date().toISOString(),
                        doc_description: '',
                        doc_id: mediaKey,
                        doc_name_originL: docTitle,
                        doc_size: size,
                        doc_summery: '',
                        doc_summery_modified_at: '',
                        doc_summery_modified_by: '',
                        doc_title: docTitle,
                        doc_type: '',
                        folder_id: folderId,
                        owner_id: user.id,
                        tags: [],
                        vector_methods: data.vectorMethods,
                        mediaKey
                    },
                }
            })

            if(mapped.length === 0) return
            filesQueueService.addCachedFiles(mapped)
            filesQueueService.setIsProcessing(true)
            updateFilesList()
        } catch (error) {
            console.error('Error getting ongoing document processing:', error)
        }

    }, [filesQueueService, user, updateFilesList])

    useEffect(() => {
        socketIOService.registerEvent('document-processing', handleDocumentEvents)
        const queue = filesQueueService.getQueue()
        if(queue.length) return
        requestOngoingDocumentProcessing()
    }, [filesQueueService, handleDocumentEvents, setUploadFilesList, user, requestOngoingDocumentProcessing])

    useEffect(() => {
        const pendingFiles = filesQueueService.getQueue().filter((item) => !item.finished)
        if (pendingFiles.length > 0 && !filesQueueService.getIsProcessing()) {
            console.log(`Found ${pendingFiles.length} files pending in queue, starting processing...`)
            processNextFile()
        }
    }, [processNextFile, uploadFilesList, filesQueueService])

    useEffect(() => {
        const handleResize = () => {
            const newMinDragWidth = -window.innerWidth * 0.76
            setMinDragWidth(newMinDragWidth)
        }
        window.addEventListener('resize', handleResize)
        window.addEventListener(UPLOAD_PROGRESS_EVENT, handleCustomEvent)
        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener(UPLOAD_PROGRESS_EVENT, handleCustomEvent)
        }
    }, [handleCustomEvent])

    // Handle mouse down event to start dragging
    const handleMouseDown = (e) => {
        setIsDragging(true) // Start dragging
        dragRef.current = e.clientX - position.x // Store initial x position
    }

    // Handle mouse move event to update position during dragging
    const handleMouseMove = useCallback((e) => {
        if (!isDragging) return // Only proceed if dragging
        let newX = e.clientX - dragRef.current // Calculate new position
        // Ensure the new position is within the allowed limits
        if (newX < minDragWidth) {
            newX = minDragWidth
        } // Prevent dragging too far left
        if (newX > maxDragWidth) {
            newX = maxDragWidth
        } // Prevent dragging too far right

        setPosition({ x: newX }) // Update position state
    }, [isDragging, minDragWidth, maxDragWidth, setPosition])

    // Handle mouse up event to stop dragging
    const handleMouseUp = () => {
        setIsDragging(false)
    }

    // Add global event listeners on mount and cleanup on unmount
    useEffect(() => {
        if (isDragging) {
            // Add event listeners to the document to track mouse movements
            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', handleMouseUp)
        } else {
            // Cleanup event listeners when dragging stops
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
        }

        // Cleanup function to remove listeners when component unmounts
        return () => {
            document.removeEventListener('mousemove', handleMouseMove)
            document.removeEventListener('mouseup', handleMouseUp)
        }
    }, [isDragging, handleMouseMove])

    const handleIconClickEvent = (index, fileData) => {
        if(!fileData) return
        const { status } = fileData
        if(status === 'pending') {
            removeFileFromQueue(index)
        }

    }
    useEffect(() => {
        if (!uploadFilesList || uploadFilesList.length === 0) return setUploadHeaderText('Upload file to start')
        const queue = filesQueueService.getQueue()
        const completedFiles = queue.filter((item) => item.finished && item.status === 'success')
        const finishedFiles = queue.filter((item) => item.finished)
        console.log({ finishedFiles, completedFiles, queue})
        if(finishedFiles.length === queue.length) return setUploadHeaderText(`Completed ${completedFiles.length} out of ${queue.length}`)
        return setUploadHeaderText(`Processing ${completedFiles.length} out of ${queue.length}`)
    }, [uploadFilesList, filesQueueService])

    return (
        <div
            className={`upload-status-window`}
            style={{
                height: 'auto',
                bottom: isExpanded ? '200px' : '79px',
                transform: `translateX(${position.x-18}px)`,
                transition: isDragging ? 'none' : 'transform 0.8s ease', // Smooth transition when not dragging
                cursor: isDragging ? 'grabbing' : 'grab', // Change cursor to grabbing when dragging
                display: uploadFilesList.length > 0 ? 'block' : 'none',
            }}
            onMouseDown={handleMouseDown}
        >
            <div className="header">
                <UploadIcon />
                <span>{ uploadHeaderText }</span>
                <div className="buttons">
                    <button className="option-btn" onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <CollapseIcon /> : <ExpandIcon />}
                    </button>
                </div>
            </div>
            {isExpanded && (
                <div className={`file-list`}>
                    {uploadFilesList.map((file, index) => {
                        return (
                            <div className="file-item" key={index}>
                                <div className="icon-name-container">
                                    <img src={file.name.trim().includes('pdf') ? pdfImg : wordImg} alt="File icon" />
                                    <span>{file.name}</span>
                                </div>
                                <div className="icon-container" onClick={() => handleIconClickEvent(index, file)}>
                                    <StatusIcon status={file.status} percentage={file.progress} spin={true}  />
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default UploadStatus
