import axios from '@/configs/axios.js';
import React, { useState, useRef, useEffect } from 'react';
import './lower-navbar.scoped.css';
import { ReactComponent as EditIcon } from '@/assets/edit-docs-icon.svg';
import { ReactComponent as DownloadIcon } from '@/assets/download-icon.svg';
import { ReactComponent as HistoryIcon } from '@/assets/history-docs-icon.svg';
import { ReactComponent as ShareIcon } from '@/assets/share-icon.svg';
import { ReactComponent as DeleteIcon } from '@/assets/delete-icon.svg';
import { ReactComponent as AddDocumentIcon } from '@/assets/chatbox-newdocs-icon-lm.svg';
import { useAtom } from 'jotai';
import { markedDocumentsAtom, documentsOpenPopUpAtom } from '@/atoms/documents';
import FilledBtn from '@/components/FilledBtn/FilledBtn';
import OutlineBtn from '@/components/OutlineBtn/OutlineBtn';
import { pathAtom } from '@/atoms/folders';
import { markedFoldersAtom } from '@/atoms/folders';

function LowerNavbar({ setIsOpen }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [documentsOpenPopUp, setDocumentsOpenPopUp] = useAtom(documentsOpenPopUpAtom);
  const [markedDocuments] = useAtom(markedDocumentsAtom);
  const [markedFolders] = useAtom(markedFoldersAtom);

  const [path] = useAtom(pathAtom);
  const isSingleDoc = markedDocuments.length === 1;

  const dropdownContainerRef = useRef(null);

  // Close the dropdown when clicking outside of btn-and-dropdown-container
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDownload = async () => {
    try {
      const response = await axios.post(
        '/api/documents/download-docs',
        {
          docs: markedDocuments.map((doc) => doc.docId),
        },
        {
          responseType: 'blob',
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'files.zip');
      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDropdownClick = (option) => {
    setIsOpen(false);
    setDocumentsOpenPopUp(option);
    setIsDropdownOpen(false);
  };

  const handleButtonClick = (option) => {
    setIsOpen(false);
    setDocumentsOpenPopUp(option);
  };

  return (
    <div className="bottom-flex">
      <div className="btn-and-dropdown-container" ref={dropdownContainerRef}>
        <FilledBtn onClick={() => setIsDropdownOpen((prev) => !prev)}>
          <AddDocumentIcon />
          New
        </FilledBtn>
        {isDropdownOpen && (
          <div className="dropdown">
            <button onClick={() => handleDropdownClick('add-document')}>Upload Files</button>
            <button onClick={() => handleDropdownClick('add-folder')}>Create Folder</button>
          </div>
        )}
      </div>
      {(markedDocuments.length > 0 || markedFolders.length > 0) && (
        <div className="bottom-left-btns">
          {markedDocuments.length > 0 && (
            <OutlineBtn onClick={handleDownload}>
              <DownloadIcon />
              Download
            </OutlineBtn>
          )}
          {(markedDocuments.length === 1 || markedFolders.length === 1) && (
            <>
              <OutlineBtn
                onClick={() =>
                  handleButtonClick(markedFolders.length === 1 ? 'edit-folder' : 'edit-document')
                }
              >
                <EditIcon />
                Edit
              </OutlineBtn>
              <OutlineBtn onClick={() => handleButtonClick('history')}>
                <HistoryIcon />
                History
              </OutlineBtn>
            </>
          )}
          <OutlineBtn>
            <ShareIcon />
            Share
          </OutlineBtn>
          <OutlineBtn onClick={() => handleButtonClick('delete')}>
            <DeleteIcon />
            Delete
          </OutlineBtn>
        </div>
      )}
    </div>
  );
}

export default LowerNavbar;
