import { userAtom } from '@/atoms/general'
import { atom } from 'jotai'
import axios from '@/configs/axios.js';
import createAuthProviderService from '../services/authProviderService'
import { loginConfigAtom } from '../atoms/general'

export const finishedLoginAtom = atom(false)
export const makeSureLoginAtom = atom(null, async (get, set, navigate) => {
  const config = get(loginConfigAtom);
  
  const authProviderService = createAuthProviderService();
  const provider = authProviderService.createProvider(config.type, config.clientPayload);
  
  const auth = await provider.getAuthInstance();

auth.onAuthStateChanged(async (user) => {
    if (user) {
      const tokenResult = await user.getIdTokenResult(true)
      console.log({tokenResult})
      if (tokenResult.claims) {
        // console.log(tokenResult.claims)


        // }
        const { data } = await axios.put('/api/user/login', {
          email: tokenResult.claims.email,
        })
        const { user } = data


      set(userAtom,{
        id:user.id.trim(), ///okta.accessToken.claims.uid,
        email:tokenResult.claims.email ,///okta.accessToken.claims.sub,
        userName:user.name ,//okta.accessToken.claims.sub,
        lang: 'english',
      })

      set(finishedLoginAtom,true)

      // console.log(!window.location.href.includes('register') && !window.location.href.includes('turbo') && !window.location.href.includes('data'))

      !window.location.href.includes('/') && !window.location.href.includes('register') && !window.location.href.includes('turbo') && !window.location.href.includes('data') && navigate.push('/playground')
      }
    } else if(!window.location.href.includes('register') && !window.location.href.includes('turbo')){
      navigate.push('/login')
    }
  })
})


export const loginUser = async (email, provider) => {
  try {
    const { data } = await axios.put('/api/user/login', { email, provider });
    return data; // Return the data for further use
  } catch (error) {
    console.error('Error logging in user:', error);
    throw error; // Propagate the error for the caller to handle
  }
};