import { Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';
import { ChartComponent } from '../ChartComponent/ChartComponent';

export const Chart = Node.create({
    name: 'chart',
    group: 'block',
    atom: true,

    addAttributes() {
        return {
            chartContent: {
                default: null,
                parseHTML: (element) =>
                    element.getAttribute('data-chart-content'),
                renderHTML: (attributes) => {
                    return {
                        'data-chart-content': attributes.chartContent,
                    };
                },
            },
            chartImage: {
                default: null,
                parseHTML: (element) =>
                    element.getAttribute('data-chart-image'),
                renderHTML: (attributes) => {
                    return {
                        'data-chart-image': attributes.chartImage,
                    };
                },
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div[data-chart-content]',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', HTMLAttributes];
    },

    addNodeView() {
        return ReactNodeViewRenderer(ChartComponent, {
            contentEditable: false,
            shouldUpdate: (node, prevNode) => {
                return node.attrs.chartContent !== prevNode.attrs.chartContent;
            },
        });
    },

    addCommands() {
        return {
            insertChart: (chartData) => ({ chain }) => {
                const chartContent = JSON.stringify({
                    type: chartData.chartType || 'line',
                    data: chartData.chartData,
                    options: chartData.options,
                });

                return chain()
                    .insertContent({
                        type: this.name,
                        attrs: { chartContent },
                    })
                    .run();
            },
        };
    },
});
