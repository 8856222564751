import { useAtom } from 'jotai';
import { isCanvasAtom } from '../../atoms/canvas';
import './CanvasChatButton.css';
import { isCollapseContentMainAtom } from '../../atoms/general';

const CanvasChatButton = () => {
    const [isCanvasMode, setIsCanvasMode] = useAtom(isCanvasAtom);
    const [, setIsMainCollapsed] = useAtom(isCollapseContentMainAtom);

    return (
        <div className="canvas-chat-container">
            <button
                className={`canvas-chat-btn ${
                    isCanvasMode ? 'active-mode' : ''
                }`}
                onClick={() => {
                    setIsCanvasMode(true);
                }}
            >
                Canvas
            </button>
            <button
                className={`canvas-chat-btn ${
                    !isCanvasMode ? 'active-mode' : ''
                }`}
                onClick={() => {
                    setIsCanvasMode(false);
                    setIsMainCollapsed(false);
                }}
            >
                Chat
            </button>
        </div>
    );
};

export default CanvasChatButton;
