import { BlockBlobClient } from '@azure/storage-blob';
import { getFileTypeByExtension } from '../../api/upload';
import { UPLOAD_PROGRESS_EVENT } from '../fileQueueService';

let serviceInstance = null;
export class AzureStorageService {
  async uploadFileToBlob({ content, fileToken, mediaKey, file, blobPath, metadata }) {
    try {
      const blobUrl = `${blobPath}/${mediaKey}?${fileToken}`;
      const blockBlobClient = new BlockBlobClient(blobUrl);

      const fileExtension = file.name
        .split('.')
        .pop()
        .toLowerCase();

      const blobContentType = getFileTypeByExtension(fileExtension);
      const parseMetadataForAzure = (obj, prefix = '') => {
        const delimeter = '_';
        return Object.entries(obj).reduce((acc, [key, value]) => {
          const newKey = prefix ? `${prefix}${delimeter}${key}` : key;
          if (typeof value === 'object' && value !== null) {
            Object.assign(acc, parseMetadataForAzure(value, newKey));
          } else {
            if (/[\u0080-\uFFFF]/.test(value)) {
              console.warn(`Non-ASCII character found in metadata value: ${value}`);
              value = encodeURIComponent(value);
            }
            acc[newKey] = String(value);
          }
          return acc;
        }, {});
      };

      const blobMetadata = parseMetadataForAzure(metadata);
      console.log({ blobMetadata });
      const uploadOptions = {
        blobHTTPHeaders: { blobContentType: blobContentType },
        blockSize: 1 * 1024 * 1024, // 4MB block size
        onProgress: (progress) => {
          console.log({ progress });
          console.log(`File size ${file.size}, uploaded ${progress.loadedBytes}`);
          const percentCompleted = Math.round((progress.loadedBytes / file.size) * 100);
          console.log({ percentCompleted });
          dispatchEvent(
            new CustomEvent(UPLOAD_PROGRESS_EVENT, {
              detail: { resourceId: mediaKey, progress: percentCompleted },
            })
          );
        },
        metadata: blobMetadata,
      };

      const response = await blockBlobClient.uploadData(content, uploadOptions);
      console.log('File uploaded to blob:', response);
      
      return response? true : false;
    } catch (err) {
      console.error('Error uploading file to blob:', err);
      return null;
    }
  }
}

export default function createAzureStorageService(fileQueueService) {
  if (!serviceInstance) {
    console.log(`Creating new AzureStorageService instance`);
    serviceInstance = new AzureStorageService(fileQueueService);
  }
  return serviceInstance;
}
