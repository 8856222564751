import microphoneWork from '@/assets/microphone-work.png';
import { ReactComponent as SearchGif } from '@/assets/search-icon.svg';
import { ReactComponent as MicLogo } from '@/assets/mic-logo.svg';
import './inputForm.scoped.scss';
import { ReactComponent as PlusIcon } from '@/assets/plus-icon-lm.svg';
import { ReactComponent as ParagraphIcon } from '@/assets/paragraph-icon-lm.svg';
import { ReactComponent as CenterIcon } from '@/assets/center-icon-lm.svg';
import { ReactComponent as MinusIcon } from '@/assets/minus-icon-lm.svg';
import { ReactComponent as LeftIcon } from '@/assets/left-icon-lm.svg';
import { ReactComponent as SendIcon } from '@/assets/send-icon-lm.svg';
import { ReactComponent as MenuIcon } from '@/assets/menu-icon-lm.svg';
import { ReactComponent as RightIcon } from '@/assets/right-icon-lm.svg';
import { ReactComponent as UploadImgIcon } from '@/assets/upload-img-icon.svg';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useState, useRef, useEffect } from 'react';
import { focusInputAtoms } from '@/atoms/inputs';
import FileList from '@/components/FileList/FileList';
import { isPreviewAtom } from '@/atoms/preview';
import { useAtom } from 'jotai';
import {
    isMobileAtom,
    userAtom,
    requestCancelTokenAtom,
    isloadingAtom,
} from '@/atoms/general';
import { LowerPart } from '@/components/Chat/Comments/Answer/LowerPart/LowerPart';
import Attachment from '@/components/Attachment/Attachment';
import ImageUploadButton from '@/components/Chat/ImageUploadButton/ImageUploadButton';
import { toolKitSettingsAtom } from '@/atoms/chat';
import { markedPromptsAtom } from '@/atoms/prompts';
import { modelsGettersAtom } from '@/atoms/models';

const winNav = window.navigator;
const isIOSChrome = winNav.userAgent.match('CriOS');

export const InputForm = ({
    generateAnswer,
    isFocus,
    question,
    files,
    role,
    handleDeleteInput,
    handleToggleRole,
    handleChange,
    placeholder,
    selectedImages,
    setSelectedImage,
    removeImg,
    isPageEnglish,
    isResponseImgUrl,
    id,
    defaultDirection,
}) => {
  // var x = window.matchMedia("(max-width: 600px)");
  /////// atoms start
  const [isMobile, setIsMobile] = useAtom(isMobileAtom);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [direction, setDirection] = useState(
    defaultDirection && defaultDirection.current ? defaultDirection.current : 'ltr'
  );
  const [mediaKey, setMediaKey] = useState('');
  const fileInputRef = useRef(null);
  const [toolKitSettings] = useAtom(toolKitSettingsAtom);
  const [markedPrompts] = useAtom(markedPromptsAtom);
  const [isPreview] = useAtom(isPreviewAtom);
  const [focusId, setFocusId] = useAtom(focusInputAtoms);
  const [modelsGetters] = useAtom(modelsGettersAtom);

    //// atoms end
    const textareaRef = useRef();

    useEffect(() => {
        const handlePaste = (event) => {
            const items = event.clipboardData.items;
            for (let i = 0; i < items.length; i++) {
                if (items[i].type.startsWith('image/')) {
                    event.preventDefault();
                    const file = items[i].getAsFile();
                    if (file) {
                        handleImageUpload(file);
                    }
                }
            }
        };

        const textarea = textareaRef.current;
        if (textarea) {
            textarea.addEventListener('paste', handlePaste);
        }

        return () => {
            if (textarea) {
                textarea.removeEventListener('paste', handlePaste);
            }
        };
    }, [textareaRef]);

    useEffect(() => {
        adjustTextareaHeight();

        const resizeObserver = new ResizeObserver(() => {
            adjustTextareaHeight();
        });

        if (textareaRef.current) {
            resizeObserver.observe(textareaRef.current.parentElement);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        adjustTextareaHeight();

        const resizeObserver = new ResizeObserver(() => {
            adjustTextareaHeight();
        });

        if (textareaRef.current) {
            resizeObserver.observe(textareaRef.current.parentElement);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const handleImageUpload = (file) => {
        // Convert the file into a format suitable for the existing logic.
        // Here you can directly call setSelectedImage or any other logic that processes the image file.
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);

        const fileInput = fileInputRef.current;
        if (fileInput) {
            fileInput.files = dataTransfer.files;
            setSelectedImage({ target: fileInput });
        }
    };

    const [alignment, setAlignment] = useState('left');

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleDirection = (event, newDirection) => {
        if (newDirection == null) {
            setDirection('ltr');
            defaultDirection.current = 'ltr';
            return;
        }
        setDirection(newDirection);
        defaultDirection.current = newDirection;
    };
    function adjustTextareaHeight() {
        const textarea = textareaRef.current;
        if (!textarea) return;

        textarea.style.height = 'auto';

        const maxHeight = 200;

        const newHeight = Math.min(textarea.scrollHeight, maxHeight);

        textarea.style.height = `${newHeight}px`;
    }
    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };
    useEffect(() => {
        window.addEventListener('resize', adjustTextareaHeight);
        return () => {
            window.removeEventListener('resize', adjustTextareaHeight);
        };
    });
    useEffect(() => {
        adjustTextareaHeight();
    });

    function adjustTextareaHeight() {
        const textarea = textareaRef.current;
        if (!textarea) return;

        // Reset height to 'auto' to get the correct scrollHeight
        textarea.style.height = 'auto';
        // Set height to scrollHeight to remove scrollbar
        textarea.style.height = textarea.scrollHeight + 'px';
    }

    function pasteIntoInput(el, text) {
        el.focus();
        if (
            typeof el.selectionStart == 'number' &&
            typeof el.selectionEnd == 'number'
        ) {
            var val = el.value;
            var selStart = el.selectionStart;
            el.value =
                val.slice(0, selStart) + text + val.slice(el.selectionEnd);
            el.selectionEnd = el.selectionStart = selStart + text.length;
        } else if (typeof document.selection != 'undefined') {
            var textRange = document.selection.createRange();
            textRange.text = text;
            textRange.collapse(false);
            textRange.select();
        }
    }
    const clickOnItem = (e, id) => {
        if (id !== focusId) {
            e.target.scrollIntoView({ behavior: 'smooth', inline: 'start' });
        }
        setFocusId(id);
    };

  const isSubmitButtonDisabled = !!question.trim() || (!!files.length && !!markedPrompts.length);

  // Check if image input is enabled for the selected model
  const imageInputEnabled = modelsGetters.isInputTypeEnabled(
    'completion',
    'chat',
    toolKitSettings.model,
    'image'
  );

  return (
    <>
      <div className="comment-container">
        <div className="flex-upper-part">
          <div className="role-btn-container">
            <button className="input-role" disabled={isPreview} onClick={() => handleToggleRole(role)}>
              {role === 'user' ? 'you' : 'ai'}
            </button>
          </div>
          {/* <div className="discard-and-input-flex">  */}
          <form className="question-input-form" onSubmit={(ev) => generateAnswer(ev, undefined, mediaKey)}>
            <div
              className={'input-container focus-bg'}
              style={{
                textAlign: direction === 'rtl' ? 'right' : 'left',
                direction: direction,
              }}
            >
              {isResponseImgUrl ? (
                <img
                  src={question}
                  height={400}
                  width={400}
                  style={{ margin: '0 auto' }}
                  alt="Sample Image"
                />
              ) : (
                <textarea
                  disabled={isPreview}
                  ref={textareaRef}
                  rows={1}
                  onClick={(e) => clickOnItem(e, id)}
                  onKeyDown={(ev) => {
                    if (ev.keyCode == 13) {
                      pasteIntoInput(this, '\n');
                      ev.preventDefault();
                      return;
                    }
                  }}
                  alt="type your text here"
                  className="question-input"
                  type="text"
                  placeholder={placeholder}
                  value={question}
                  onChange={handleChange}
                />
              )}
              <div className="submit-btn-strip">
                {
                  <>
                    {isMobile ? (
                      <button className="submit-btn-mobile">
                        {' '}
                        <SendIcon
                          style={{ transform: direction === 'rtl' ? 'rotate(180deg)' : 'none' }}
                        />{' '}
                      </button>
                    ) : (
                      <button
                        className={`submit-btn ${!isSubmitButtonDisabled && 'disabled'}`}
                        disabled={!isSubmitButtonDisabled}
                      >
                        Send
                      </button>
                    )}
                  </>
                }
              </div>
            </div>
            {files.length > 0 && <FileList files={files} id={id} />}
          </form>
          {isMobile ? (
            <>{/* <div className="menu-options-container">
          <MenuIcon/>
           </div> */}</>
          ) : (
            <div className="settings">
              {!isPreview && (
                <div className="options-flex">
                  {<Attachment id={id} />}
                  {imageInputEnabled && (
                    <>
                      <UploadImgIcon
                        onClick={handleImageClick}
                        style={{ position: 'relative', top: ' -2px' }}
                      />
                      <input
                        type="file"
                        accept="image/*"
                        multiple={true}
                        id="image-upload"
                        onChange={setSelectedImage}
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                      />
                    </>
                  )}

                  {
                    <ToggleButtonGroup
                      disabled={isPreview}
                      value={direction}
                      exclusive
                      onChange={handleDirection}
                      aria-label="text alignment"
                    >
                      <ToggleButton
                        value="rtl"
                        aria-label="justified"
                        sx={{
                          border: 'none',
                          '&:hover': { backgroundColor: 'transparent' },
                          '&.Mui-selected, &.Mui-selected:hover': {
                            backgroundColor: 'transparent',

                            // Add any other styles you want for the active state here
                          },
                        }}
                      >
                        {direction === 'rtl' ? <RightIcon /> : <LeftIcon />}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  }
                  <button
                    style={{
                      marginLeft: !imageInputEnabled,
                    }}
                    className="close-comment-icon"
                    onClick={handleDeleteInput}
                  >
                    {' '}
                    <MinusIcon />{' '}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {imageInputEnabled && (
          <div className="upload-img-container">
            <ImageUploadButton
              selectedImages={selectedImages}
              removeImg={removeImg}
              ref={fileInputRef}
            />
          </div>
        )}
        {isMobile && (
          <div className="settings">
            {!isPreview && (
              <div className="options-flex">
                {<Attachment id={id} />}
                {imageInputEnabled && (
                  <>
                    <UploadImgIcon
                      onClick={handleImageClick}
                      style={{ position: 'relative', top: ' -2px' }}
                    />
                    <input
                      type="file"
                      accept="image/*"
                      id="image-upload"
                      multiple="multiple"
                      onChange={setSelectedImage}
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                    />
                  </>
                )}
                <ToggleButtonGroup
                  disabled={isPreview}
                  value={direction}
                  exclusive
                  onChange={handleDirection}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value="rtl"
                    aria-label="justified"
                    sx={{
                      border: 'none',

                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                            '&.Mui-selected, &.Mui-selected:hover': {
                                                backgroundColor: 'transparent',
                                                // Add any other styles you want for the active state here
                                            },
                                        }}
                                    >
                                        {direction === 'rtl' ? (
                                            <div className="top">
                                                <RightIcon />
                                            </div>
                                        ) : (
                                            <div className="top">
                                                <LeftIcon />
                                            </div>
                                        )}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <button
                                    className="close-comment-icon"
                                    onClick={handleDeleteInput}
                                >
                                    {' '}
                                    <MinusIcon />{' '}
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};
