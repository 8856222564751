import microphoneWork from '@/assets/microphone-work.png';
import { ReactComponent as SearchGif } from '@/assets/search-icon.svg';
import { ReactComponent as MicLogo } from '@/assets/mic-logo.svg';
import './answer.scoped.css';
import { ReactComponent as PlusIcon } from '@/assets/plus-icon-lm.svg';
import { ReactComponent as ParagraphIcon } from '@/assets/paragraph-icon-lm.svg';
import { ReactComponent as CenterIcon } from '@/assets/center-icon-lm.svg';
import { ReactComponent as MinusIcon } from '@/assets/minus-icon-lm.svg';
import { ReactComponent as DownloadButton } from '@/assets/chat-download-icon.svg';
import { ReactComponent as ResourceLinkImg } from '@/assets/resource-link-img.svg';
import { ReactComponent as LeftIcon } from '@/assets/left-icon-lm.svg';

import { ReactComponent as RightIcon } from '@/assets/right-icon-lm.svg';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useEffect, useRef, useState } from 'react';
import { isPreviewAtom } from '@/atoms/preview';
import { toolKitSettingsAtom } from '@/atoms/chat';
import { useAtom } from 'jotai';
import { handleStopTypeAtom } from '@/atoms/logs';
import Typewriter from 'typewriter-effect';
import LowerPart from './LowerPart/LowerPart';
import { focusInputAtoms } from '@/atoms/inputs';
import Attachment from '@/components/Attachment/Attachment';
import FileList from '@/components/FileList/FileList';
import { ReactComponent as SendIcon } from '@/assets/send-icon-lm.svg';
import {
    isMobileAtom,
    userAtom,
    requestCancelTokenAtom,
    isloadingAtom,
} from '@/atoms/general';
import Lottie from 'react-lottie';
import animationData from '@/assets/three-dot-animation.json'; // Adjust the path to your Lottie file
import { ResourcePage } from '@/pages/ResourcePage/ResourcePage';
import { Editor } from '@tinymce/tinymce-react';

// import gif from '@/assets/'
const winNav = window.navigator;
const isIOSChrome = winNav.userAgent.match('CriOS');
export const Answer = ({
    id,
    isFocus,
    comment,
    files,
    generateAnswer,
    question,
    downloadImg,
    isLike,
    role,
    show,
    arrOfAnswer,
    handleDeleteInput,
    handleToggleRole,
    handleChange,
    toType,
    placeholder,
    isResponseImgUrl,
    loadingAns = false,
    resources,
    defaultDirection,
}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    const [, handleStopType] = useAtom(handleStopTypeAtom);
    var x = window.matchMedia('(max-width: 600px)');
    const [isPreview] = useAtom(isPreviewAtom);
    const [toolKit] = useAtom(toolKitSettingsAtom);
    const [answersIndex, setAnswersIndex] = useState(0);
    const [focusId, setFocusId] = useAtom(focusInputAtoms);
    const [isMobile, setIsMobile] = useAtom(isMobileAtom);
    const [direction, setDirection] = useState(
        defaultDirection && defaultDirection.current
            ? defaultDirection.current
            : 'ltr'
    );
    const [openResourcePopUp, setOpenResourcePopUp] = useState(false);
    const handleEditorChange = () => {};
    const handleIncrement = () => {
        if (answersIndex === arrOfAnswer.length - 1) {
            return;
        }
        setAnswersIndex((ans) => ans + 1);
    };
    const handleDecrement = () => {
        if (answersIndex === 0) {
            return;
        }
        setAnswersIndex((ans) => ans - 1);
    };

    const [isTyping, setIsTyping] = useState('none');
    const textareaRef = useRef();
    const [alignment, setAlignment] = useState('left');

    useEffect(() => {
        window.addEventListener('resize', adjustTextareaHeight);
        return () => {
            window.removeEventListener('resize', adjustTextareaHeight);
        };
    });
    useEffect(() => {
        //adjust the height immedialty if not typeEFFECY, if do typeEffect, wait for textarea to be define and typing effect to end
        if (
            (!loadingAns && !isTyping && textareaRef.current) ||
            (!toolKit.isTypingEffect && !loadingAns)
        ) {
            adjustTextareaHeight();
        }
    });
    useEffect(() => {
        adjustTextareaHeight();

        const resizeObserver = new ResizeObserver(() => {
            adjustTextareaHeight();
        });

        if (textareaRef.current) {
            resizeObserver.observe(textareaRef.current.parentElement);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);
    const handleDirection = (event, newDirection) => {
        if (newDirection == null) {
            setDirection('ltr');
            defaultDirection.current = 'ltr';
            return;
        }
        setDirection(newDirection);
        defaultDirection.current = newDirection;
    };
    function adjustTextareaHeight() {
        const textarea = textareaRef.current;
        if (!textarea) return;

        textarea.style.height = 'auto';

        const maxHeight = 200;

        const newHeight = Math.min(textarea.scrollHeight, maxHeight);

        // Set height with the bounded value
        textarea.style.height = `${newHeight}px`;
    }
    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };
    const clickOnItem = (e, id) => {
        if (id !== focusId) {
            e.target.scrollIntoView({ behavior: 'smooth', inline: 'start' });
        }
        handleChange(e);
        setFocusId(id);
    };

    function pasteIntoInput(el, text) {
        if (el) {
            el.focus();
            if (
                typeof el.selectionStart == 'number' &&
                typeof el.selectionEnd == 'number'
            ) {
                var val = el.value;
                var selStart = el.selectionStart;
                el.value =
                    val.slice(0, selStart) + text + val.slice(el.selectionEnd);
                el.selectionEnd = el.selectionStart = selStart + text.length;
            } else if (typeof document.selection != 'undefined') {
                var textRange = document.selection.createRange();
                textRange.text = text;
                textRange.collapse(false);
                textRange.select();
            }
        }
    }

    if (loadingAns) {
        return (
            <div
                className="loading-ans-container"
                style={{ alignItems: 'center', justifyContent: 'flex-start' }}
            >
                <div className="role-btn-container">
                    <button className="input-role" disabled>
                        {' '}
                        ai
                    </button>
                </div>

                <div
                    style={{ width: '100px', height: '25px', overflow: 'auto' }}
                >
                    <Lottie options={defaultOptions} />
                </div>
            </div>
        );
    }

    const setHtmlContent = (html, editor) => {
        if (editor) {
            editor.setContent(html);
        }
    };
    const OpenResourceNewTab = (resource) => {
        console.log(resource);
        setOpenResourcePopUp(resource);
    };
    const isSendDisabled = arrOfAnswer
        ? arrOfAnswer[answersIndex].trim() === ''
        : question.trim() === '';

    return (
        <>
            <div className="comment-container">
                <div className="flex-upper-part">
                    <div className="role-btn-container">
                        <button
                            className="input-role"
                            disabled={isPreview}
                            onClick={() => handleToggleRole(role)}
                        >
                            {role === 'user' ? 'You' : 'AI'}
                        </button>
                    </div>
                    {/* <div className="discard-and-input-flex">  */}
                    <form
                        className="question-input-form"
                        onSubmit={(ev) => generateAnswer(ev, undefined)}
                    >
                        <div
                            className={`input-container ${
                                isFocus ? 'focus-bg' : ''
                            }`}
                            style={{
                                textAlign:
                                    direction === 'rtl' ? 'right' : 'left',
                                direction: direction,
                            }}
                        >
                            {toolKit.isTypingEffect && toType && isTyping ? (
                                // toolKit.isTypingEffect && isTyping  && toType
                                <Typewriter
                                    options={{
                                        skipAddStyles: true,
                                        delay: 8,
                                        wrapperClassName: 'question-type-input',
                                    }}
                                    onInit={(typewriter) => {
                                        typewriter
                                            .typeString(question)
                                            .callFunction(() => {
                                                typewriter.stop();
                                                handleStopType({ key: id });

                                                setIsTyping(false);
                                            })
                                            .start();
                                    }}
                                    className={'question-input ltr'}
                                />
                            ) : isResponseImgUrl ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div className="image-container">
                                        <DownloadButton
                                            className="download-btn"
                                            onClick={() =>
                                                downloadImg(question)
                                            }
                                        />
                                        <img
                                            src={question}
                                            height={400}
                                            width={400}
                                            style={{ margin: '0 auto' }}
                                            alt="Sample Image"
                                        />
                                    </div>
                                    <p>Here is your picture!</p>
                                </div>
                            ) : (
                                // <iframe src={question} frameborder="0" />
                                <textarea
                                    disabled={isPreview}
                                    ref={textareaRef}
                                    cols={1}
                                    onClick={(e) => clickOnItem(e, id)}
                                    onKeyDown={(ev) => {
                                        if (ev.keyCode == 13) {
                                            pasteIntoInput(this, '\n');
                                            ev.preventDefault();
                                            return;
                                        }
                                    }}
                                    alt="type your text here"
                                    className="question-input"
                                    type="text"
                                    placeholder={placeholder}
                                    value={
                                        arrOfAnswer
                                            ? arrOfAnswer[answersIndex]
                                            : question
                                    }
                                    onChange={handleChange}
                                />
                            )}
                            <div className="submit-btn-strip">
                                {isFocus && (
                                    <>
                                        {isMobile ? (
                                            <button className="submit-btn-mobile">
                                                {' '}
                                                <SendIcon
                                                    style={{
                                                        transform:
                                                            direction === 'rtl'
                                                                ? 'rotate(180deg)'
                                                                : 'none',
                                                    }}
                                                />{' '}
                                            </button>
                                        ) : (
                                            <button
                                                className={`submit-btn ${
                                                    isSendDisabled
                                                        ? 'disabled'
                                                        : ''
                                                }`}
                                                disabled={isSendDisabled}
                                            >
                                                Send
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        {files.length > 0 && <FileList files={files} id={id} />}
                    </form>
                    <div className="right-flex">
                        {!isPreview && !isMobile && (
                            <>
                                <div className="settings">
                                    {isFocus && <Attachment id={id} />}

                                    {isFocus && (
                                        <ToggleButtonGroup
                                            disabled={isPreview}
                                            value={direction}
                                            exclusive
                                            onChange={handleDirection}
                                            aria-label="text alignment"
                                        >
                                            <ToggleButton
                                                value="rtl"
                                                aria-label="justified"
                                                sx={{
                                                    border: 'none',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            'transparent',
                                                    },
                                                    '&.Mui-selected, &.Mui-selected:hover': {
                                                        backgroundColor:
                                                            'transparent',
                                                        // Add any other styles you want for the active state here
                                                    },
                                                }}
                                            >
                                                {direction === 'rtl' ? (
                                                    <RightIcon />
                                                ) : (
                                                    <LeftIcon />
                                                )}
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    )}
                                    <button
                                        className="close-comment-icon"
                                        onClick={handleDeleteInput}
                                    >
                                        {' '}
                                        <MinusIcon />{' '}
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {resources && resources.length > 0 && (
                    <div className="resources-container">
                        {resources.map((resource, index) => (
                            <>
                                <div
                                    onClick={() => OpenResourceNewTab(resource)}
                                    className="single-resource-link"
                                    key={index}
                                >
                                    <ResourceLinkImg />
                                    Resource {index + 1}
                                </div>
                            </>
                        ))}
                    </div>
                )}

                <LowerPart
                    id={id}
                    isLike={isLike}
                    handleDecrement={handleDecrement}
                    handleIncrement={handleIncrement}
                    resources={resources}
                    answersIndex={answersIndex}
                    arrOfAnswer={arrOfAnswer}
                    show={show}
                    comment={comment}
                    handleDeleteInput={handleDeleteInput}
                    text={arrOfAnswer ? arrOfAnswer[answersIndex] : question}
                />

                {isMobile && (
                    <div className="settings">
                        {!isPreview && (
                            <div className="options-flex">
                                {isFocus && <Attachment id={id} />}
                                <ToggleButtonGroup
                                    disabled={isPreview}
                                    value={direction}
                                    exclusive
                                    onChange={handleDirection}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton
                                        value="rtl"
                                        aria-label="justified"
                                        sx={{
                                            border: 'none',
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                            '&.Mui-selected, &.Mui-selected:hover': {
                                                backgroundColor: 'transparent',
                                                // Add any other styles you want for the active state here
                                            },
                                        }}
                                    >
                                        {direction === 'rtl' ? (
                                            <RightIcon />
                                        ) : (
                                            <LeftIcon />
                                        )}
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <button
                                    className="close-comment-icon"
                                    onClick={handleDeleteInput}
                                >
                                    {' '}
                                    <MinusIcon />{' '}
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {/* </div> */}
            {openResourcePopUp && (
                <ResourcePage
                    resource={openResourcePopUp}
                    setOpenResourcePopUp={setOpenResourcePopUp}
                />
            )}
        </>
    );
};
