import './DropdownDownload.css';
import { ReactComponent as DownloadIcon } from '@/assets/chat-download-icon.svg';

const DropdownDownload = ({ generateDownload }) => {
    return (
        <div className="download-container">
            <div className="download-chat-container">
                <DownloadIcon />
            </div>
            <div className="download-dropdown">
                <div
                    className="dropdown-item"
                    onClick={async (e) => {
                        e.stopPropagation();
                        await generateDownload(true);
                    }}
                >
                    RTL Download (Recommended for Hebrew)
                </div>
                <div
                    className="dropdown-item"
                    onClick={async (e) => {
                        e.stopPropagation();
                        await generateDownload(false);
                    }}
                >
                    LTR Download (Recommended for English)
                </div>
            </div>
        </div>
    );
};

export default DropdownDownload;
