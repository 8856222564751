import axios from '@/configs/axios.js';

export const JWT_REFRESH_CHECK_INTERVAL = 5000;
export const ACCESS_JWT_EXPIRATION = 300000;

export const refreshUserJwtToken = async () => {
  try {
    console.log('Refreshing JWT token');
    const res = await axios.post('/api/v1/iam/playground/refresh-token');
    if (res.status !== 200) {
      throw new Error(`Error refreshing JWT token, got: ${res.status}`);
    }
    console.log('Updated axios token');
    axios.updateAxiosToken(res.data.token);
    return true;
  } catch (error) {
    console.log('Error refreshing JWT token:', error);
    return false;
  }
};

export const getFileUploadToken = async (inputFileName, uploadType = 'data') => {
  try {
    const res = await axios.post('/api/v1/storage/playground/get-token', {
      fileName: inputFileName,
      uploadType,
    });
    if (res.status !== 200) {
      throw new Error(`Error getting upload token, got: ${res.status}`);
    }
    const { token, mediaKey, type, blobPath } = res.data;
    return { fileToken: token, mediaKey, type, blobPath };
  } catch (err) {
    console.log(err);
  }
};
