import axios from '@/configs/axios.js';

export const createDocVectors = async (data) => {
  try {
    const { vectors, userId, docId, fixed } = data;
    const res = await axios.post('/api/documents/create-vectors', {
      vectors,
      userId,
      docId,
      fixed,
      // lastUpdated: Date.now().toString(),
      // updatedBy: user.id,
    });
    if (res.status !== 200) return false;
    return true;
  } catch (error) {
    console.error('Error creating vectors:', error);
    return false;
  }
};

export const editDocVectors = async (data) => {
  try {
    const { vectors, fixed, lastUpdatedAt, lastUpdatedBy } = data;
    const res = await axios.put('/api/documents/edit-vectors', {
      vectors, fixed, lastUpdatedAt,
      lastUpdatedBy
    });
    if (res.status !== 200) return false;
    return true;
  } catch (error) {
    console.error('Error editing vectors:', error);
    throw error;
  }
};

export const deleteDocVectors = async (data) => {
  try {
    const { vectors } = data;
    const res = await axios.post('/api/documents/delete-vectors', {
      vectors,
    });
    if (res.status !== 200) return false;
    return true;
  } catch (error) {
    console.error('Error deleting vectors:', error);
    return false;
  }
};
