import { atomWithStorage } from 'jotai/utils';

export const toolKitSettingsAtom = atomWithStorage('toolkit', {
  chatLang: 'none',
  template: 'none',
  model: '',
  isMultiple: false,
  isLongAnswer: false,
  isTypingEffect: false,
  temperature: 0,
  question_choice: ['Vectors', 'Q&A'],
  embeddingModel: '',
});
