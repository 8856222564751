import './prompts-main.scoped.css';
import React from 'react';
import { useEffect, useState } from 'react';
import PromptsTable from '../PromptsTable/PromptsTable';
import LowerNavbar from '../LowerNavbar/LowerNavbar';
import AddEditModalPrompt from '@/components/AddEditModal/AddEditModalPrompt';
import { markedPromptsAtom, promptsOpenPopUpAtom, activeFolderAtom, pathAtom } from '@/atoms/prompts';
import { useAtom } from 'jotai';
import { ReactComponent as BackIcon } from '@/assets/back-icon.svg';
import { getPromptsAndFoldersAtom } from '@/api/prompts';
import { searchAtom, filteredPromptsAtom, sortOptionAtom, timeToggleAtom } from '@/atoms/prompts';
import DeleteModalPrompt from '../DeleteModal/DeleteModalPrompt';
import SkeletonTable from '@/components/SkeletonTable/SkeletonTable';
import { skeletonPromptsAtom } from '@/atoms/general';
import SearchInput from '@/components/SearchInput/SearchInput';
import SelectInput from '@/components/SelectInput/SelectInput';
import { selectOptions } from '@/constants/table/selectOptions';
import headersSettings from '@/constants/table/headers';

function PromptsMain() {
  const [documentsOpenPopUp, setDocumentsOpenPopUp] = useAtom(promptsOpenPopUpAtom);
  const [path, setPath] = useAtom(pathAtom);
  const [markedPrompts, setMarkedPrompts] = useAtom(markedPromptsAtom);
  const [promptsTableData] = useAtom(filteredPromptsAtom);
  const [, getDocumentsAndFolders] = useAtom(getPromptsAndFoldersAtom);
  const [activeFolder, setActiveFolder] = useAtom(activeFolderAtom);
  const [isSkeleton] = useAtom(skeletonPromptsAtom);
  const [searchQuery, setSearchQuery] = useAtom(searchAtom);
  const [, setTimeToggle] = useAtom(timeToggleAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useAtom(sortOptionAtom);

  useEffect(() => {
    getDocumentsAndFolders();
  }, [activeFolder, documentsOpenPopUp]);

  const closeFolderPopup = () => {
    setDocumentsOpenPopUp('');
  };

  const closeAddEditPopup = async () => {
    setDocumentsOpenPopUp('');
  };

  const closeDeletePopup = () => {
    setDocumentsOpenPopUp('');
    setMarkedPrompts([]);
  };

  const handleSearchChange = ({ target }) => {
    setSearchQuery(target.value);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setTimeToggle((prev) => !prev);
    setIsOpen(false);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleGoBack = () => {
    if (path.length === 1) {
      setActiveFolder('');
    } else {
      setActiveFolder({ id: path[path.length - 2].id, name: path[path.length - 2].name });
    }
    setPath((prevPath) => prevPath.slice(0, -1));
  };

  const renderBreadCrumbs = () => {
    return path.map((folder, index) => (
      <span key={folder.id}>
        {index > 0 && '/'}
        <button
          className="breadcrumbs-btn"
          onClick={() => {
            setPath(path.slice(0, index + 1));
            setActiveFolder({ id: path[index].id, name: path[index].name });
          }}
        >
          {folder.name}
        </button>
      </span>
    ));
  };

  const handleMarkChange = (event, prompt_id, title, description, content) => {
    const markedDoc = markedPrompts.filter((item) => item.prompt_id === prompt_id);
    if (markedDoc.length) {
      setMarkedPrompts((prev) => prev.filter((item) => item.prompt_id !== prompt_id));
    } else {
      setMarkedPrompts((prev) => [
        ...prev,
        {
          prompt_id: prompt_id,
          description: description,
          title: title,
          content: content,
        },
      ]);
    }
  };

  const handleFolderPress = (e, id, name) => {
    e.preventDefault();
    setPath((prev) => [...prev, { parenId: activeFolder ? activeFolder.id : null, id: id, name: name }]);

    setActiveFolder({ id, name });
    // setIsNotInFolder(false);
    // getDocumentsOfFolder();
  };

  return (
    <div className="documents-main-container">
      <div className="folders-container">
        <div className="page-subheader">
          <div className="left-breadcrumbs">
            <div className="back-button">
              {path.length > 0 && (
                <button onClick={handleGoBack}>
                  <BackIcon />
                </button>
              )}
            </div>
            <span className="breadcrumbs">Prompts/{renderBreadCrumbs()}</span>
          </div>

          <div className="search-container">
            <SearchInput isDisable={isSkeleton} handleSearch={handleSearchChange} value={searchQuery} />
            <SelectInput
              isDisable={isSkeleton}
              isDropdown={isOpen}
              handleDropdown={toggleDropdown}
              handleClick={handleOptionClick}
              options={selectOptions}
              selected={selectedOption}
            />
          </div>
        </div>

        {/* <div className="flex-folders-files">
            <Folders
              options={options}
              search={search}
              handleOptionsChange={handleOptionsChange}
              handleFolderPress={handleFolderPress}
              handleSearchChange={handleSearchChange}
            />
          </div> */}
      </div>

      {isSkeleton && !promptsTableData.length ? (
        <SkeletonTable />
      ) : (
        <PromptsTable
          handleFolderPress={handleFolderPress}
          promptsToShowUser={promptsTableData}
          settings={headersSettings['prompts']}
        />
      )}
      <LowerNavbar setIsOpen={setIsOpen} />

      {/* {//
      documentsOpenPopUp === "add-folder" && (
        <AddFolderModal
          header={"Add new Folder"}
          isOpen={documentsOpenPopUp === "add-folder"}
          subHeader={""}
          onClose={closeFolderPopup}
        />
      )} */}

      {documentsOpenPopUp === 'delete-prompt' && (
        <DeleteModalPrompt
          header={'Delete Prompt'}
          isOpen={documentsOpenPopUp === 'delete-prompt'}
          subHeader={'i would like to delete the following prompts'}
          onClose={closeDeletePopup}
        />
      )}

      {documentsOpenPopUp === 'edit-prompt' && (
        <AddEditModalPrompt
          header={markedPrompts[0].isAdmin ? 'Inspect Prompt' : 'Edit Prompt'}
          subHeader={markedPrompts[0].isAdmin ? 'Inspect current prompt' : 'Edit current prompt'}
          isOpen={documentsOpenPopUp === 'edit-prompt'}
          action={'edit'}
          onClose={closeAddEditPopup}
        />
      )}

      {documentsOpenPopUp === 'add-prompt' && (
        <AddEditModalPrompt
          header={'New Prompt'}
          subHeader={'Fill following details to create new promt'}
          isOpen={documentsOpenPopUp === 'add-prompt'}
          action={'add'}
          onClose={closeAddEditPopup}
        />
      )}
      {/* <DocumentsPopup isOpenPopUp={isOpenPopUp} setIsOpenPopUp={setIsOpenPopUp}/> */}
    </div>
  );
}

export default PromptsMain;
