import axios from '@/configs/axios.js';
import { atom } from 'jotai';
import { inputsAtoms } from './inputs';
import { userAtom } from './general';
import { prevLogIdAtom } from './login';

export const activeLogAtom = atom('');

// export const handleChangeEditable
export const handleStopTypeAtom = atom(null, async (get, set, val) => {
  const { key } = val;
  //  ev.target.closest

  console.log(key);

  let newAnswers = [...get(inputsAtoms)];
  newAnswers = newAnswers.map((input) => {
    return input.key === key ? { ...input, toType: false } : input;
  });
  // newAnswers[index].comment = ev.target.value

  set(inputsAtoms, [...newAnswers]);
});

export const handleCommentChangeAtom = atom(null, async (get, set, val) => {
  const { key, ev } = val;
  //  ev.target.closest

  if (ev.key === 'Enter') return;
  let newAnswers = [...get(inputsAtoms)];
  newAnswers = newAnswers.map((input) => {
    return input.key === key ? { ...input, comment: ev.target.value } : input;
  });
  // newAnswers[index].comment = ev.target.value

  set(inputsAtoms, [...newAnswers]);
});

export const handleCommentCloseAtom = atom(null, async (get, set, val) => {
  const logId = get(prevLogIdAtom);
  const { key } = val;

  let newAnswers = [...get(inputsAtoms)];
  newAnswers = newAnswers.map((input) => {
    return input.key === key ? { ...input, show: false } : input;
  });
  // newAnswers[index].comment = ev.target.value

  set(inputsAtoms, [...newAnswers]);
  try {
    await axios.put('/api/logs/comment-close', {
      logId: logId,
      key: key,
      userId: get(userAtom).id,
    });
  } catch (error) {
    console.log(error);
  }
});

export const handleCommentSaveAtom = atom(null, async (get, set, val) => {
  const logId = get(prevLogIdAtom);
  const { key, e } = val;
  try {
    await axios.put('/api/logs/comment-save', {
      logId: logId,
      key: key,
      userId: get(userAtom).id,
      comment: e.target.value,
    });
  } catch (error) {
    console.log(error);
  }
});

export const toggleLikeAtom = atom(null, async (get, set, val) => {
  const logId = get(prevLogIdAtom);
  const { key } = val;
  let newAnswers = [...get(inputsAtoms)];
  let likedAnswer = newAnswers.filter((input) => input.key === key)[0].isLike;
  likedAnswer = likedAnswer === 'like' ? false : 'like';
  newAnswers = newAnswers.map((input) => {
    return input.key === key ? { ...input, isLike: likedAnswer } : input;
  });

  set(inputsAtoms, [...newAnswers]);

  try {
    await axios.put('/api/logs/toggle-like', {
      logId: logId,
      key: key,
      userId: get(userAtom).id,
      isLike: likedAnswer,
    });
  } catch (error) {
    console.log(error);
  }
});
export const toggleDislikeAtom = atom(null, async (get, set, val) => {
  const logId = get(prevLogIdAtom);
  const { key } = val;
  let newAnswers = [...get(inputsAtoms)];
  let likedAnswer = newAnswers.filter((input) => input.key === key)[0].isLike;
  likedAnswer = likedAnswer === 'dislike' ? false : 'dislike';
  newAnswers = newAnswers.map((input) => {
    return input.key === key ? { ...input, isLike: likedAnswer } : input;
  });

  set(inputsAtoms, [...newAnswers]);

  try {
    await axios.put('/api/logs/toggle-like', {
      logId: logId,
      userId: get(userAtom).id,
      isLike: likedAnswer,
    });
  } catch (error) {
    console.log(error);
  }
});

export const handleFileAddAtom = atom(null, (get, set, val) => {
  const { key, file, id } = val;

  const fileObj = { name: file.name, id: id, type: file.type };
  let newAnswers = [...get(inputsAtoms)];
  console.log(newAnswers);
  newAnswers = newAnswers.map((input) => {
    return input.key === key ? { ...input, files: [...input.files, fileObj] } : input;
  });
  set(inputsAtoms, [...newAnswers]);
});

export const handleFileRemoveAtom = atom(null, (get, set, val) => {
  const { key, fileId } = val;
  console.log(key, fileId);
  try {
    // axios.delete(`/doc/delete-from-azure/${fileId}`)
  } catch (err) {
    console.log(err);
  }
  let newAnswers = [...get(inputsAtoms)];
  newAnswers = newAnswers.map((input) => {
    return input.key === key
      ? { ...input, files: input.files.filter((file) => file.id !== fileId) }
      : input;
  });
  set(inputsAtoms, [...newAnswers]);
});
