import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import createAuthProviderService from '@/services/authProviderService';
import { finishedLoginAtom } from '@/api/users';
import { loginConfigAtom } from '@/atoms/general';

export const ActiveDirectoryProvider = ({ handleLogin, loginUserWithData }) => {
  const [config] = useAtom(loginConfigAtom);
  const navigate = useHistory();

  const authProviderService = createAuthProviderService();
  const provider = authProviderService.createProvider(config.type, config.clientPayload);

  useEffect(() => {
    const handleLoginProvider = async () => {
      console.log('handle login provider');

      try {
        await provider.initialize();
        const activeDirectoryResponse = await provider.login();

        if (activeDirectoryResponse) {

          // Login to jeen and create jeen token
          const isLogin = await handleLogin({
            email: activeDirectoryResponse.account.username.toLowerCase(),
            providerToken: activeDirectoryResponse.accessToken,
          });

          if (isLogin) {
            loginUserWithData();
          }
        }
      } catch (err) {
        console.log(err);
        navigate.push('/');
      }
    };
    handleLoginProvider();
  }, []);

  return <></>;
};
