import {
    Document,
    Paragraph,
    TextRun,
    AlignmentType,
    HeadingLevel,
    Packer,
    Table,
    TableRow,
    TableCell,
    WidthType,
    TextDirection,
} from 'docx';
import { saveAs } from 'file-saver';

export const generateWordDocument = async (editor, isRTL = false) => {
    if (!editor) {
        console.error('Editor instance is not available');
        return;
    }

    try {
        const editorContent = editor.getJSON();

        if (!editorContent.content || editorContent.content.length === 0) {
            console.error('No content to export');
            return;
        }

        const doc = new Document({
            numbering: {
                config: [
                    {
                        reference: 'bullet',
                        levels: [
                            {
                                level: 0,
                                format: 'bullet',
                                text: '•',
                                alignment: isRTL ? 'right' : 'left',
                            },
                        ],
                    },
                    {
                        reference: 'number',
                        levels: [
                            {
                                level: 0,
                                format: 'decimal',
                                text: '%1.',
                                start: 1,
                                alignment: isRTL ? 'right' : 'left',
                            },
                        ],
                    },
                ],
            },
            sections: [
                {
                    properties: {
                        bidi: true,
                        rtl: isRTL,
                    },
                    children: parseEditorContent(editorContent, isRTL),
                },
            ],
        });

        const blob = await Packer.toBlob(doc);
        saveAs(blob, 'canvas_content.docx');
    } catch (error) {
        console.error('Error generating document:', error);
    }
};

function parseEditorContent(jsonContent, isRTL = false) {
    const docElements = [];

    jsonContent.content.forEach((node) => {
        switch (node.type) {
            case 'paragraph':
                docElements.push(createParagraph(node, isRTL));
                break;
            case 'heading':
                docElements.push(createHeading(node, isRTL));
                break;
            case 'bulletList':
                node.content.forEach((listItem) => {
                    docElements.push(createBulletPoint(listItem, isRTL));
                });
                break;
            case 'orderedList':
                node.content.forEach((listItem, index) => {
                    docElements.push(
                        createNumberedPoint(listItem, index + 1, isRTL)
                    );
                });
                break;
            case 'hardBreak':
                docElements.push(new Paragraph({}));
                break;
            case 'table':
                docElements.push(createTable(node, isRTL));
                break;
        }
    });

    return docElements;
}

function createParagraph(node, isRTL = false) {
    const textRuns = createTextRuns(node, isRTL);

    if (textRuns.length === 0) {
        return new Paragraph({
            children: [new TextRun('')],
            spacing: {
                after: 200,
                line: 360,
            },
            bidirectional: true,
            rtl: isRTL,
            textDirection: isRTL
                ? TextDirection.RIGHT_TO_LEFT
                : TextDirection.LEFT_TO_RIGHT,
        });
    }

    return new Paragraph({
        children: textRuns,
        alignment: getAlignment(node.attrs?.textAlign, isRTL),
        spacing: {
            after: 200,
            line: 360,
        },
        bidirectional: true,
        rtl: isRTL,
        textDirection: isRTL
            ? TextDirection.RIGHT_TO_LEFT
            : TextDirection.LEFT_TO_RIGHT,
    });
}

function createTextRuns(node, isRTL = false) {
    if (!node.content) return [];

    const runs = [];

    node.content.forEach((textNode) => {
        if (textNode.type === 'hardBreak') {
            runs.push(new TextRun({ text: '\n', break: 1 }));
            return;
        }

        let shadingOptions;
        if (textNode.marks?.some((mark) => mark.type === 'highlight')) {
            shadingOptions = {
                type: 'highlight',
                fill: 'FFFF00',
                color: 'auto',
            };
        }

        const textParts = (textNode.text || '').split('\n');
        textParts.forEach((part, index) => {
            if (index > 0) {
                runs.push(new TextRun({ break: 1 }));
            }

            if (part.length > 0) {
                runs.push(
                    new TextRun({
                        text: part,
                        bold: textNode.marks?.some(
                            (mark) => mark.type === 'bold'
                        ),
                        italics: textNode.marks?.some(
                            (mark) => mark.type === 'italic'
                        ),
                        strike: textNode.marks?.some(
                            (mark) => mark.type === 'strike'
                        ),
                        shading: shadingOptions,
                        rightToLeft: isRTL,
                    })
                );
            }
        });
    });

    return runs;
}

function createHeading(node, isRTL = false) {
    const textRuns = createTextRuns(node, isRTL);
    return new Paragraph({
        children: textRuns,
        heading: HeadingLevel[`HEADING_${node.attrs.level}`],
        alignment: isRTL ? AlignmentType.RIGHT : AlignmentType.LEFT,
    });
}

function createBulletPoint(node, isRTL = false) {
    let textRuns = [];
    if (node.content) {
        node.content.forEach((contentNode) => {
            if (contentNode.type === 'paragraph') {
                textRuns = textRuns.concat(createTextRuns(contentNode, isRTL));
            }
        });
    }

    const standardIndent = {
        start: 400,
        end: 0,
        hanging: 200,
    };

    return new Paragraph({
        children: textRuns,
        numbering: {
            reference: 'bullet',
            level: 0,
        },
        spacing: {
            before: 200,
            after: 300,
            line: 200,
        },
        indent: standardIndent,
        bidirectional: isRTL,
        rtl: isRTL,
        textDirection: isRTL
            ? TextDirection.RIGHT_TO_LEFT
            : TextDirection.LEFT_TO_RIGHT,
    });
}

function createNumberedPoint(node, number, isRTL = false) {
    let textRuns = [];
    if (node.content) {
        node.content.forEach((contentNode) => {
            if (contentNode.type === 'paragraph') {
                textRuns = textRuns.concat(createTextRuns(contentNode, isRTL));
            }
        });
    }

    const standardIndent = {
        start: 400,
        end: 0,
        hanging: 200,
    };

    return new Paragraph({
        children: textRuns,
        numbering: {
            reference: 'number',
            level: 0,
            num: number,
        },
        spacing: {
            before: 200,
            after: 300,
            line: 200,
        },
        indent: standardIndent,
        bidirectional: isRTL,
        rtl: isRTL,
        textDirection: isRTL
            ? TextDirection.RIGHT_TO_LEFT
            : TextDirection.LEFT_TO_RIGHT,
    });
}
function createTable(node, isRTL = false) {
    const rows = node.content.map((rowNode, rowIndex) => {
        const cells = rowNode.content.map((cellNode) => {
            const cellContent = cellNode.content
                ? cellNode.content.map((contentNode) =>
                      createParagraph(contentNode, isRTL)
                  )
                : [
                      new Paragraph({
                          bidirectional: true,
                          rtl: isRTL,
                          textDirection: isRTL
                              ? TextDirection.RIGHT_TO_LEFT
                              : TextDirection.LEFT_TO_RIGHT,
                      }),
                  ];

            return new TableCell({
                children: cellContent,
                width: {
                    size: 100 / rowNode.content.length,
                    type: WidthType.PERCENTAGE,
                },

                ...(rowIndex === 0 && {
                    shading: {
                        fill: 'F2F2F2',
                    },
                    verticalAlign: 'center',
                }),
            });
        });

        return new TableRow({
            children: cells,
            tableHeader: rowIndex === 0,
        });
    });

    return new Table({
        rows: rows,
        width: {
            size: 100,
            type: WidthType.PERCENTAGE,
        },

        margins: {
            top: 120,
            bottom: 120,
            right: 120,
            left: 120,
        },
        borders: {
            top: { style: 'single', size: 1 },
            bottom: { style: 'single', size: 1 },
            left: { style: 'single', size: 1 },
            right: { style: 'single', size: 1 },
            insideHorizontal: { style: 'single', size: 1 },
            insideVertical: { style: 'single', size: 1 },
        },
    });
}
function getAlignment(align, isRTL = false) {
    if (!align) {
        return isRTL ? AlignmentType.RIGHT : AlignmentType.LEFT;
    }

    switch (align) {
        case 'left':
            return isRTL ? AlignmentType.RIGHT : AlignmentType.LEFT;
        case 'center':
            return AlignmentType.CENTER;
        case 'right':
            return isRTL ? AlignmentType.LEFT : AlignmentType.RIGHT;
        default:
            return isRTL ? AlignmentType.RIGHT : AlignmentType.LEFT;
    }
}
