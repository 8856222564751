import { Node } from '@tiptap/core';
import 'katex/dist/katex.min.css';
import katex from 'katex';

export const MathExtension = Node.create({
    name: 'math',

    group: 'inline',

    inline: true,

    atom: true,

    addAttributes() {
        return {
            content: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'math-inline',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['math-inline', HTMLAttributes];
    },

    addNodeView() {
        return ({ node }) => {
            const dom = document.createElement('span');
            dom.className = 'math-inline';

            try {
                katex.render(node.attrs.content, dom, {
                    throwOnError: false,
                });
            } catch (error) {
                console.error('KaTeX render error:', error);
            }

            return {
                dom,
            };
        };
    },
});
