import './expandable-table.scoped.css';
import React, { useState } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, Pagination } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ArrowIcon } from '@/assets/keyboard-arrow.svg';
import { userAtom } from '@/atoms/general';
import { useAtom } from 'jotai';
import { groupsAtomWithUpdates } from '@/atoms/admin';
import { modelsGettersAtom } from '@/atoms/models';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import Snackbar from '@/components/Snackbar/Snackbar';
import {
  updateGroupToken,
  updateGroupModel,
  updateGroupUsersToken,
  updateGroupUsersModel,
} from '@/api/admin';
import { TableInnerRow } from './TableInnerRow/TableInnerRow';
import Tooltip from '@/components/Tooltip/Tooltip';

const useStyles = makeStyles(() => ({
  ul: {
    '& .MuiPaginationItem-root': {
      backgroundColor: 'var(--main-undertone-clr)',
    },
  },
}));

const ExpandableTable = ({ group, settings, isExpanded, onExpand }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [, setGroups] = useAtom(groupsAtomWithUpdates);
  const [user, setUser] = useAtom(userAtom);
  const [modelsGetters] = useAtom(modelsGettersAtom);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 8;
  const classes = useStyles();

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getToken = (tokenValue) => {
    const chosen = settings[1].options.find((option) => option.value === tokenValue);
    return chosen;
  };

  const getModelNameByValue = (modelsArray) => {
    const chosen = modelsArray.map((model) => {
      if (model && model.length) {
        const modelFound = modelsGetters.getModelName('completion', 'chat', model);
        if (modelFound && modelFound.length) {
          return modelFound;
        } else {
          return model;
        }
      }
    });
    return chosen;
  };

  const getModelValueByName = (modelsArray) => {
    const chosen = modelsArray.map((model) => {
      if (model && model.length) {
        const modelFound = modelsGetters.getModelValue('completion', 'chat', model);
        if (modelFound && modelFound.length) {
          return modelFound;
        } else {
          return model;
        }
      }
    });
    return chosen;
  };

  const handleTokenChange = async (event) => {
    const currentModelsValue = group.default_max_tokens_per_user;
    const newTokenValue = event.target.value;
    setGroups({
      type: 'updateGroupToken',
      payload: { groupId: group.group_id, val: newTokenValue },
    });
    try {
      const isGroupUpdate = await updateGroupToken({ groupId: group.group_id, token: newTokenValue });
      const isGroupUsersUpdate = await updateGroupUsersToken({
        groupId: group.group_id,
        token: newTokenValue,
      });
      if (isGroupUpdate && isGroupUsersUpdate) {
        if (user.groupId === group.group_id) {
          setUser((prev) => ({ ...prev, tokenLimit: newTokenValue }));
        }
        enqueueSnackbar('Group Token Has been Updated.', {
          content: (key, message) => <Snackbar type={'success'} message={message} />,
        });
      } else {
        setGroups({
          type: 'updateGroupModel',
          payload: { groupId: group.group_id, val: currentModelsValue },
        });
        enqueueSnackbar('Failed to Update Group Model.', {
          content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
      }
    } catch (error) {}
  };

  const handleModelChange = async (event) => {
    const currentModelsValue = group.default_model;
    const newModelsValue = getModelValueByName(event.target.value);
    setGroups({
      type: 'updateGroupModel',
      payload: { groupId: group.group_id, val: newModelsValue },
    });
    const isGroupUpdate = await updateGroupModel({ groupId: group.group_id, model: newModelsValue });
    const isGroupUsersUpdate = await updateGroupUsersModel({
      groupId: group.group_id,
      model: newModelsValue,
    });
    if (isGroupUpdate && isGroupUsersUpdate) {
      if (user.groupId === group.group_id) {
        setUser((prev) => ({ ...prev, modelAvailable: newModelsValue }));
      }
      enqueueSnackbar('Group Model Has been Updated.', {
        content: (key, message) => <Snackbar type={'success'} message={message} />,
      });
    } else {
      setGroups({
        type: 'updateGroupModel',
        payload: { groupId: group.group_id, val: currentModelsValue },
      });
      enqueueSnackbar('Failed to Update Group Model.', {
        content: (key, message) => <Snackbar type={'error'} message={message} />,
      });
    }
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = group.users.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <>
      <tr className="group-row">
        <td style={{ display: 'contents' }}>
          <button
            style={{
              marginLeft: '40%',
              marginTop: '18px',
              transform: isExpanded ? '' : 'rotate(180deg)',
              transition: 'transform 150ms ease',
              border: 'none',
            }}
            onClick={onExpand}
          >
            <ArrowIcon />
          </button>
        </td>
        <td style={{ lineHeight: 'normal' }}>
          <Tooltip value={group.group_name}>
            <b>{group.group_name}</b>
          </Tooltip>
        </td>
        <td style={{ textAlign: 'center', paddingRight: '30px' }}>--</td>
        <td style={{ textAlign: 'center', paddingRight: '30px' }}>--</td>
        <td style={{ textAlign: 'center', paddingRight: '30px' }}>--</td>
        <td style={{ display: 'flex', justifyContent: 'center', paddingRight: '30px' }}>
          <Select
            className="select-box-tool-box"
            size="medium"
            id={`${Math.floor(Math.random() * 25)}`}
            value={getToken(group.default_max_tokens_per_user).value}
            style={{ width: '100px' }}
            label="Token"
            name="token"
            onChange={handleTokenChange}
          >
            {settings[1].options.map((option) => (
              <MenuItem key={uuidv4()} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </td>
        <td style={{ textAlign: 'center', paddingRight: '30px' }}>--</td>
        <td>
          <Select
            className="select-box-tool-box"
            size="medium"
            id={`${Math.floor(Math.random() * 25)}`}
            multiple
            value={getModelNameByValue(group.default_model)}
            renderValue={(selected) => selected.join(', ')}
            label="Model"
            name="model"
            onChange={handleModelChange}
          >
            {settings[0].options.map((option) => (
              <MenuItem key={uuidv4()} value={option.value}>
                <Checkbox checked={getModelNameByValue(group.default_model).includes(option.value)} />
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </td>
      </tr>

      {isExpanded && (
        <>
          {currentRows.map((user) => (
            <TableInnerRow key={user.id} user={user} group={group} settings={settings} />
          ))}
          {group.users.length > rowsPerPage && (
            <tr>
              <td colSpan="8">
                <div className="pagination-container">
                  <Pagination
                    count={Math.ceil(group.users.length / rowsPerPage)}
                    classes={{ ul: classes.ul }}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </div>
              </td>
            </tr>
          )}
        </>
      )}
    </>
  );
};

export default ExpandableTable;
