import './admin-main.scoped.css';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { AdminTable } from '@/components/AdminTab/AdminTable/AdminTable';
import SkeletonTable from '@/components/SkeletonTable/SkeletonTable';
import SearchInput from '@/components/SearchInput/SearchInput';
import SelectInput from '@/components/SelectInput/SelectInput';
import { selectOptions } from '@/constants/table/selectOptions';
import { getGroupsUsers } from '@/api/admin';
import { skeletonAdminAtom } from '@/atoms/general';
import headersSettings from '@/constants/table/headers';
import LowerNavbar from '@/components/AdminTab/LowerNavbar/LowerNavbar';
import { useAtom } from 'jotai';
import {
  groupsAtom,
  filteredGroupsAtom,
  searchAtom,
  sortOptionAtom,
  timeToggleAtom,
} from '@/atoms/admin';

export function AdminMain() {
  const [isSkeleton, setIsSkeleton] = useAtom(skeletonAdminAtom);
  const [selectedOption, setSelectedOption] = useAtom(sortOptionAtom);
  const [searchQuery, setSearchQuery] = useAtom(searchAtom);
  const [filteredGroups] = useAtom(filteredGroupsAtom);
  const [, setTimeToggle] = useAtom(timeToggleAtom);
  const [groups, setGroups] = useAtom(groupsAtom);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!groups.length) {
      console.log('getting groups');
      getGroups();
    }
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setTimeToggle((prev) => !prev);
    setIsOpen(false);
  };

  const getGroups = async () => {
    try {
      setIsSkeleton(true);
      const data = await getGroupsUsers();
      const filteredGroups = data.map((group) => ({
        ...group,
        users: group.users.filter((user) => user.model !== null && user.token_limit !== null),
        // default_model: [defaultName],
      }));
      setGroups(filteredGroups);
    } catch (error) {
      setIsSkeleton(false);
    } finally {
      setIsSkeleton(false);
    }
  };

  const handleSearchChange = ({ target }) => {
    setSearchQuery(target.value);
  };

  return (
    <div>
      <div className="search-container">
        <SearchInput isDisable={isSkeleton} handleSearch={handleSearchChange} value={searchQuery} />
        <SelectInput
          isDisable={isSkeleton}
          isDropdown={isOpen}
          handleDropdown={toggleDropdown}
          handleClick={handleOptionClick}
          options={selectOptions.filter((option) => option !== 'Date')}
          selected={selectedOption}
        />
      </div>
      {!filteredGroups.length && isSkeleton ? (
        <SkeletonTable />
      ) : (
        <AdminTable groups={filteredGroups} settings={headersSettings['admin']} />
      )}
      <LowerNavbar />
    </div>
  );
}
