// export function getMarkedFiles(files, subfolders) {
//     let markedFiles = [];

//     // Check top-level files
//     markedFiles = markedFiles.concat(files.filter(file => file.marked).map((file)=>({ docId:file.doc_id,docTitle: file.doc_title,docType: file.doc_type,
//         docDescription: file.doc_description, vectorMethods:file.vector_methods,docNameOrigin: file.doc_name_origin})))

//     // Check files in folders
//     subfolders.forEach(folder => {
//         markedFiles = markedFiles.concat(getMarkedFilesInFolder(folder).map((file)=>({ docId:file.doc_id,docTitle: file.doc_title,docType: file.doc_type,
//             docDescription: file.doc_description, vectorMethods:file.vector_methods,docNameOrigin: file.doc_name_origin})));
//     });
//     console.log('marked-file-array:',markedFiles)
//     return markedFiles;
// }

// function getMarkedFilesInFolder(folder) {
//     let markedFiles = [];

//     // Add marked files from this folder
//     markedFiles = markedFiles.concat(folder.files.filter(file => file.marked));
//     console.log(markedFiles)
//     // Recurse into subfolders
//     folder.subfolders.forEach(subfolder => {
//         markedFiles = markedFiles.concat(getMarkedFilesInFolder(subfolder).map((file)=>({ docId:file.doc_id,docTitle: file.doc_title,docType: file.doc_type,
//             docDescription: file.doc_description, vectorMethods:file.vector_methods,docNameOrigin: file.doc_name_origin})));
//     });

//     return markedFiles;
// }


export function getMarkedFiles(files, subfolders) {
    let markedFiles = [];

    // Check top-level files
    markedFiles = markedFiles.concat(
        files.filter(file => file.marked)
             .map(file => transformFile(file))
    );

    // Check files in folders
    subfolders.forEach(folder => {
        markedFiles = markedFiles.concat(getMarkedFilesInFolder(folder));
    });
    if (markedFiles.length) {
        console.log('marked-file-array:', markedFiles);
    }
    return markedFiles;
}

function getMarkedFilesInFolder(folder) {
    let markedFiles = [];

    // Add marked files from this folder
    markedFiles = markedFiles.concat(
        folder.files.filter(file => file.marked)
             .map(file => transformFile(file))
    );

    // Recurse into subfolders
    folder.subfolders.forEach(subfolder => {
        markedFiles = markedFiles.concat(getMarkedFilesInFolder(subfolder));
    });

    return markedFiles;
}

function transformFile(file) {
    return {
        docId: file.doc_id,
        docTitle: file.doc_title,
        docType: file.doc_type,
        tags:file.tags,
        docDescription: file.doc_description,
        vectorMethods: file.vector_methods,
        docNameOrigin: file.doc_name_origin,
        embeddingModel: file.embedding_model,
    };
}
