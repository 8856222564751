import axios from '@/configs/axios.js';

export const fetchLoginConfig = async (provider) => {
  try {
    const response = await axios.post(`/auth/v1/public/providers/get-config`, provider);
    return response.data;
  } catch (error) {
    console.error('Error fetching provider:', error);
    throw error;
  }
};
