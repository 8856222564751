import './documents-main.scoped.css';
import React, { useState, useEffect } from 'react';
import DocumentsTable from '@/components/DocumentsTab/DocumentsTable/DocumentsTable';
import LowerNavbar from '@/components/DocumentsTab/LowerNavbar/LowerNavbar';
import { userFoldersAtom } from '@/atoms/folders';
import AddEditModal from '@/components/AddEditModal/AddEditModal';
import { activeFolderAtom, pathAtom } from '@/atoms/folders';
import { useAtom } from 'jotai';
import { ReactComponent as BackIcon } from '@/assets/back-icon.svg';
import DeleteModal from '../DeleteModal/DeleteModal';
import AddEditFolderModal from '../AddEditFolderModal/AddEditFolderModal';
import {
  documentsOpenPopUpAtom,
  filteredDocsAtom,
  sortOptionAtom,
  searchAtom,
  timeToggleAtom,
} from '@/atoms/documents';
import { DocumentsHistory } from '../DocumentsHistory/DocumentsHistory';
import RestoreHistory from '@/components/RestoreHistory/RestoreHistory';
import SkeletonTable from '@/components/SkeletonTable/SkeletonTable';
import { skeletonDataAtom } from '@/atoms/general';
import SearchInput from '@/components/SearchInput/SearchInput';
import SelectInput from '@/components/SelectInput/SelectInput';
import { selectOptions } from '@/constants/table/selectOptions';
import headersSettings from '@/constants/table/headers';
import { use } from 'react';

function DocumentsMain() {
  const [documentsOpenPopUp, setDocumentsOpenPopUp] = useAtom(documentsOpenPopUpAtom);
  const [filteredDocs] = useAtom(filteredDocsAtom);
  const [folders] = useAtom(userFoldersAtom);
  const [path, setPath] = useAtom(pathAtom);
  const [activeFolder, setActiveFolder] = useAtom(activeFolderAtom);
  const [searchQuery, setSearchQuery] = useAtom(searchAtom);
  const [, setTimeToggle] = useAtom(timeToggleAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useAtom(sortOptionAtom);
  const [isSkeleton] = useAtom(skeletonDataAtom);

  const closeFolderPopup = () => {
    setDocumentsOpenPopUp('');
  };
  const closeAddEditPopup = () => {
    setDocumentsOpenPopUp('');
  };
  const closeDeletePopup = () => {
    setDocumentsOpenPopUp('');
  };
  const closeHistoryPopup = () => {
    setDocumentsOpenPopUp('');
  };

  const handleSearchChange = ({ target }) => {
    setSearchQuery(target.value);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setTimeToggle((prev) => !prev);
    setIsOpen(false);
  };

  const handleGoBack = () => {
    if (path.length === 1) {
      setActiveFolder('');
    } else {
      setActiveFolder({ id: path[path.length - 2].id, name: path[path.length - 2].name });
    }
    setPath((prevPath) => prevPath.slice(0, -1));
  };

  const renderBreadCrumbs = () => {
    return (
      <>
        <span>
          <button
            className="breadcrumbs-btn"
            onClick={() => {
              setPath([]);
              setActiveFolder('');
            }}
          >
            Documents/
          </button>
        </span>{' '}
        {path.map((folder, index) => (
          <span key={folder.id}>
            {index > 0 && '/'}
            <button
              className="breadcrumbs-btn"
              onClick={() => {
                setPath(path.slice(0, index + 1));
                setActiveFolder({ id: path[index].id, name: path[index].name });
              }}
            >
              {folder.name}
            </button>
          </span>
        ))}
      </>
    );
  };

  const handleFolderPress = (e, id, name) => {
    setSearchQuery('');
    setSelectedOption('');
    setPath((prev) => [...prev, { parenId: activeFolder ? activeFolder.id : null, id: id, name: name }]);
    setActiveFolder({ id, name });
  };

  return (
    <div className="documents-main-container">
      <div className="folders-container">
        <div className="page-subheader">
          <div className="left-breadcrumbs">
            <div className="back-button">
              {path.length > 0 && (
                <button onClick={handleGoBack}>
                  <BackIcon />
                </button>
              )}
            </div>
            <span className="breadcrumbs">{renderBreadCrumbs()}</span>
          </div>

          <div className="search-container">
            <SearchInput isDisable={isSkeleton} handleSearch={handleSearchChange} value={searchQuery} />
            <SelectInput
              isDisable={isSkeleton}
              isDropdown={isOpen}
              handleDropdown={toggleDropdown}
              handleClick={handleOptionClick}
              options={selectOptions}
              selected={selectedOption}
            />
          </div>
        </div>
      </div>
      {isSkeleton ? (
        <SkeletonTable />
      ) : (
        <DocumentsTable
          key={123}
          handleFolderPress={handleFolderPress}
          documentsToShowUser={filteredDocs}
          folders={folders}
          isUpload={true}
          settings={headersSettings['documents']}
          isDocs={false} // boolean for use of table in bot documents or data tab
        />
      )}
      {documentsOpenPopUp === 'delete' && (
        <DeleteModal
          header={'Delete Files'}
          isOpen={documentsOpenPopUp === 'delete'}
          subHeader={'i would like to delete the following files'}
          onClose={closeDeletePopup}
        />
      )}
      {documentsOpenPopUp === 'add-folder' && (
        <AddEditFolderModal
          header={'Add new Folder'}
          isOpen={documentsOpenPopUp === 'add-folder'}
          subHeader={''}
          onClose={closeFolderPopup}
          action={'add'}
        />
      )}
      {documentsOpenPopUp === 'edit-folder' && (
        <AddEditFolderModal
          header={'edit Folder'}
          isOpen={documentsOpenPopUp === 'edit-folder'}
          subHeader={''}
          onClose={closeFolderPopup}
          action={'edit'}
        />
      )}
      {documentsOpenPopUp === 'history' && <DocumentsHistory onClose={closeHistoryPopup} />}
      {documentsOpenPopUp === 'edit-document' && (
        <AddEditModal
          header={'edit-document'}
          subHeader={'Drag or Attach your Document to the screen'}
          isOpen={documentsOpenPopUp === 'edit-document'}
          action={'edit'}
          onClose={closeAddEditPopup}
        />
      )}
      {documentsOpenPopUp === 'add-document' && (
        <AddEditModal
          header={'Add New Document'}
          subHeader={'Drag or Attach your Document to the screen'}
          isOpen={documentsOpenPopUp === 'add-document'}
          action={'add'}
          onClose={closeAddEditPopup}
        />
      )}

      <LowerNavbar setIsOpen={setIsOpen} />

      {documentsOpenPopUp === 'history' && <RestoreHistory />}
    </div>
  );
}

export default DocumentsMain;
