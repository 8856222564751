import React, { useState, useEffect, useCallback, memo } from "react";
import { Autocomplete, TextField, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";
import TooltipModel from "../Tooltip/TooltipModel/TooltipModel";

const CustomPopper = styled(Popper)(({}) => ({
  '&[data-popper-placement="bottom"]': {
    marginTop: "40px !important",
    transform: "translateX(-233px) !important",
  },
  "& .MuiAutocomplete-listbox": {
    width: "500px",
    maxHeight: "none",
    overflow: "visible",
  },
  "& .MuiAutocomplete-option": {
    fontSize: "17px",
    fontFamily: "Assistant",
    color: "var(--text-clr)",
    '&[aria-selected="true"]': {
      backgroundColor: "var(--selected-background-clr)",
    },
    "&:hover": {
      backgroundColor: "var(--lighest-grey-clr)",
    },
  },
  "& .MuiPaper-root": {
    width: "500px",
    maxHeight: "none",
    overflow: "visible",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  },
}));

const MemoizedCustomPopper = memo((props) => <CustomPopper {...props} />);

const AutoComplete = memo(
  ({
    className,
    size = "small",
    name,
    value,
    updateValue,
    disabled,
    options = [],
  }) => {
    const [shownValue, setShownValue] = useState(null);

    useEffect(() => {
      // Only update shownValue if we have both value and options
      if (options.length > 0) {
        // Find the matching option, handle both value being direct or nested
        const currentOption = options.find(
          (option) => option.value === value || option.value === value?.value
        );
        setShownValue(currentOption || null);
      }
    }, [value, options]);

    const handleChange = useCallback(
      (event, newValue) => {
        if (!newValue) return;

        setShownValue(newValue);
        updateValue?.({
          name: name,
          value: newValue.value,
        });
      },
      [name, updateValue]
    );

    const renderOption = useCallback((props, option) => {
      if (!option) return null;

      console.log("Rendering option with tooltip:", option); // For debugging

      return (
        <TooltipModel option={option} key={option.value}>
          <li {...props}>{option.label}</li>
        </TooltipModel>
      );
    }, []);

    const renderInput = useCallback(
      (params) => (
        <TextField
          {...params}
          size={size}
          sx={{
            "& .MuiInputBase-input": {
              lineHeight: "1.5",
              width: "100px",
              paddingRight: "8px",
            },
          }}
        />
      ),
      [size]
    );

    const isOptionEqualToValue = useCallback((option, value) => {
      if (!option || !value) return false;
      return option.value === (value.value || value);
    }, []);

    const getOptionLabel = useCallback((option) => {
      if (!option) return "";
      return option.label || option.value || "";
    }, []);

    // Safety check for options
    const validOptions = Array.isArray(options) ? options : [];

    return (
      <Autocomplete
        disablePortal
        className={className}
        options={validOptions}
        value={shownValue}
        onChange={handleChange}
        disabled={disabled}
        disableClearable
        PopperComponent={MemoizedCustomPopper}
        sx={{
          width: 133,
          "& .MuiOutlinedInput-root": {
            backgroundColor: "var(--lighter-grey-clr)",
            borderRadius: "8px",
            height: "30px",
            fontSize: "16px",
            fontFamily: "Assistant",
          },
        }}
        renderOption={renderOption}
        renderInput={renderInput}
        isOptionEqualToValue={isOptionEqualToValue}
        getOptionLabel={getOptionLabel}
      />
    );
  }
);

AutoComplete.displayName = "AutoComplete";

export default AutoComplete;