import './MenuBar.scss';
const MenuBar = ({ editor }) => {
    if (!editor) return null;

    return (
        <div className="editor-menu">
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .toggleBold()
                        .run()
                }
                className={editor.isActive('bold') ? 'is-active' : ''}
            >
                B
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .toggleItalic()
                        .run()
                }
                className={editor.isActive('italic') ? 'is-active' : ''}
            >
                I
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .toggleStrike()
                        .run()
                }
                className={editor.isActive('strike') ? 'is-active' : ''}
            >
                S
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .toggleCode()
                        .run()
                }
                className={editor.isActive('code') ? 'is-active' : ''}
            >
                {'</>'}
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .setParagraph()
                        .run()
                }
            >
                P
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .toggleHeading({ level: 1 })
                        .run()
                }
                className={
                    editor.isActive('heading', { level: 1 }) ? 'is-active' : ''
                }
            >
                H1
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .toggleHeading({ level: 2 })
                        .run()
                }
                className={
                    editor.isActive('heading', { level: 2 }) ? 'is-active' : ''
                }
            >
                H2
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .toggleBulletList()
                        .run()
                }
            >
                •
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .toggleOrderedList()
                        .run()
                }
            >
                1.
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .toggleHighlight()
                        .run()
                }
            >
                H
            </button>
            <button
                onClick={() => editor.commands.setTextDirection('rtl')}
                className={
                    editor.isActive({ textDirection: 'rtl' }) ? 'is-active' : ''
                }
            >
                RTL
            </button>
            <button
                onClick={() => editor.commands.setTextDirection('ltr')}
                className={
                    editor.isActive({ textDirection: 'ltr' }) ? 'is-active' : ''
                }
            >
                LTR
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .setTextAlign('left')
                        .run()
                }
                className={
                    editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''
                }
            >
                ←
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .setTextAlign('center')
                        .run()
                }
                className={
                    editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''
                }
            >
                ↔
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .setTextAlign('right')
                        .run()
                }
                className={
                    editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''
                }
            >
                →
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                        .run()
                }
                disabled={editor.isActive('table')}
            >
                Table
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .addColumnBefore()
                        .run()
                }
                disabled={!editor.can().addColumnBefore()}
            >
                Add Column Before
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .addColumnAfter()
                        .run()
                }
                disabled={!editor.can().addColumnAfter()}
            >
                Add Column After
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .deleteColumn()
                        .run()
                }
                disabled={!editor.can().deleteColumn()}
            >
                Delete Column
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .addRowBefore()
                        .run()
                }
                disabled={!editor.can().addRowBefore()}
            >
                Add Row Before
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .addRowAfter()
                        .run()
                }
                disabled={!editor.can().addRowAfter()}
            >
                Add Row After
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .deleteRow()
                        .run()
                }
                disabled={!editor.can().deleteRow()}
            >
                Delete Row
            </button>
            <button
                onClick={() =>
                    editor
                        .chain()
                        .focus()
                        .deleteTable()
                        .run()
                }
                disabled={!editor.can().deleteTable()}
            >
                Delete Table
            </button>
        </div>
    );
};

export default MenuBar;
