import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';
import { MainPage } from './pages/MainPage/MainPage';
import { WelcomePage } from './pages/WelcomePage/WelcomePage';
import { Login } from './pages/LoginPage/LoginPage';
import { LoginOkta } from './components/Login/LoginOkta';
import { Register } from './pages/RegisterPage/Register';
import { useCallback, useEffect } from 'react';
import { socketIOService, userAtom, loginConfigAtom } from './atoms/general';
import { LOGIN_CONFIG_TYPE } from './constants/enums';
import { fetchLoginConfig } from './api/providers';
import { finishedLoginAtom, loginUser } from '@/api/users';
import { refreshUserJwtToken } from '@/api/jwt.js';
import setColors from './hooks/setColors';
import { Provider } from 'jotai';
import { TurboPage } from './pages/TurboPage';
import { useAtom } from 'jotai';
import { propertiesAtom } from './atoms/general';
import { toolKitSettingsAtom } from '@/atoms/chat';
import { SnackbarProvider } from 'notistack';
import { getDocumentsAndFoldersAtom } from '@/api/documents';
import { getPromptsAndFoldersAtom } from '@/api/prompts';
import { getProperties } from '@/api/properties';
import { logout } from './actions/auth.action';
import { ACCESS_JWT_EXPIRATION, JWT_REFRESH_CHECK_INTERVAL } from './api/jwt';
import { toRelativeUrl, OktaAuth } from '@okta/okta-auth-js';
import { Security, LoginCallback, useOktaAuth } from '@okta/okta-react';
import { fetchEnabledFeaturesAtom } from './atoms/features';
import axios from './configs/axios';

const oktaAuth = new OktaAuth({
  issuer: 'https://ormat.okta.com/oauth2/default',
  clientId: '0oaz8rf9n1JY013Ed2p7',
  redirectUri: window.location.origin + '/login/callback',
});

function App() {
  const [, setProperties] = useAtom(propertiesAtom);
  const [finishedLogin, setFinishedLogin] = useAtom(finishedLoginAtom);
  const [, getDocumentsAndFolders] = useAtom(getDocumentsAndFoldersAtom);
  const [, getPromptsAndFolders] = useAtom(getPromptsAndFoldersAtom);
  const [, setToolKitSettings] = useAtom(toolKitSettingsAtom);
  const [, fetchEnabledFeatures] = useAtom(fetchEnabledFeaturesAtom);
  const [user, setUser] = useAtom(userAtom);
  const [loginConfig, setLoginConfig] = useAtom(loginConfigAtom);
  const navigate = useHistory();
  let authState;
  const okta = useOktaAuth();
  if (okta) authState = okta.authState;
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate.replace(toRelativeUrl(originalUri || '/', window.location.origin), { replace: true });
  };
  const refreshToken = useCallback(async () => {
    const now = new Date().getTime();
    const lastAuthTimestamp = sessionStorage.getItem('lastAuthTimestamp') || 0;
    if (
      lastAuthTimestamp &&
      now - lastAuthTimestamp < ACCESS_JWT_EXPIRATION - ACCESS_JWT_EXPIRATION / 10
    ) {
      console.log('JWT token not about to expire, skipping refresh');
      // console.log('JWT token not about to expire, skipping refresh')
      // JWT token not about to expire, skipping refresh
      setTimeout(refreshToken, JWT_REFRESH_CHECK_INTERVAL);
      return true;
    }
    console.log('JWT token about to expire, refreshing');
    const userStorage = JSON.parse(sessionStorage.getItem('user'));

    if (!userStorage || !userStorage.email) {
      console.log('No user found in session storage');
      setFinishedLogin(false);
      logout();
      return false;
    }
    if (!finishedLogin) {
      const data = await loginUser(userStorage.email, loginConfig.config);
      if (!data || !data.token) {
        // setFinishedLogin(false);
        logout();
        return false;
      }
      axios.updateAxiosToken(data.token);
    } else {
      const refreshed = await refreshUserJwtToken();
      if (!refreshed) {
        setFinishedLogin(false);
        logout();
        return false;
      }
    }
    sessionStorage.setItem('lastAuthTimestamp', now);
    if (!finishedLogin) {
      return false;
    }
    setTimeout(refreshToken, JWT_REFRESH_CHECK_INTERVAL);
    return true;
  }, [setFinishedLogin, finishedLogin]);
  // Handles jwt token refresh
  useEffect(() => {
    async function checkRefresh() {
      const sessionUser = user || sessionStorage.getItem('user');
      if (!sessionUser) {
        sessionStorage.removeItem('lastAuthTimestamp');
        sessionStorage.removeItem('user');
        return;
      }
      setUser(sessionUser);
      const refreshed = await refreshToken();
      if (!refreshed) {
        setFinishedLogin(false);
        logout();
        return;
      }
      // console.log('Setting finished login');
      setFinishedLogin(true);
    }
    checkRefresh();
  }, [refreshToken, user, setFinishedLogin, setUser]);
  useEffect(() => {
    const setChatToolkit = (settings) => {
      setToolKitSettings({
        chatLang: settings['translate'].default_value,
        template: 'none',
        model: '',
        isMultiple: settings['answers'].default_value,
        isLongAnswer: settings['scope'].default_value,
        isTypingEffect: settings['typing'].default_value,
        temperature: settings['temperature'].default_value,
        question_choice: settings['data'].default_value,
        embeddingModel: '',
      });
    };
    const setSettings = async () => {
      setFinishedLogin(false);
      sessionStorage.removeItem('lastAuthTimestamp');
      const settings = await getProperties();
      const config = await fetchLoginConfig();
      // const config = {
      //   type: 'ACTIVE_DIRECTORY',
      //   clientPayload: {
      //     auth: {
      //       clientId: '9c014263-9493-436b-a18c-1c2486ea8a87',
      //       authority: 'https://login.microsoftonline.com/fcbfa8c7-8f83-409a-8239-ab85cff601ac',
      //     },
      //     scopes: ['openid', 'profile', 'User.Read', 'GroupMember.Read.All', 'Group.Read.All'],
      //   },
      // };
      await setColors(settings.colors);
      setProperties(settings);
      setChatToolkit(settings.toolkit);
      setLoginConfig(config);
    };
    setSettings();
  }, []);
  useEffect(() => {
    if (!finishedLogin) {
      socketIOService.disconnect();
      return;
    }
    // Connect socket service
    socketIOService.connect();
  }, [finishedLogin]);
  useEffect(() => {
    if (!finishedLogin || !user) return;
    getDocumentsAndFolders();
    getPromptsAndFolders();
    fetchEnabledFeatures();

    return () => {
      socketIOService.deRegisterEvent('client-id');
    };
  }, [finishedLogin, socketIOService, user]);
  return (
    <>
      {loginConfig.type === LOGIN_CONFIG_TYPE.OKTA ? (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
          <>
            {user ? (
              <Route exact path="/" render={() => <MainPage />} />
            ) : (
              <Route path="/" render={() => <LoginOkta />} />
            )}
            {/* <Route exact path="/" render={() => <WelcomePage />} /> */}
            <Route exact path="/playground" render={() => <MainPage />} />
            <Route exact path="/register" render={() => <Register />} />
            <Route path="/turbo" render={() => <TurboPage />} />
            <Route path="/login/callback" component={LoginCallback} />
          </>
        </Security>
      ) : (
        <>
          <Route exact path="/" render={() => <WelcomePage />} />
          <Route exact path="/register" render={() => <Register />} />
          <Route exact path="/playground" render={() => <MainPage />} />
          <Route path="/turbo" render={() => <TurboPage />} />
          <Route path="/login" render={() => <Login />} />
        </>
      )}
    </>
  );
}
const AppWithRouterAccess = () => (
  //   <HttpsRedirect>
  <Provider>
    <Router>
      <SnackbarProvider maxSnack={1}>
        <App />
      </SnackbarProvider>
    </Router>
  </Provider>
  //   </HttpsRedirect>
);
export default AppWithRouterAccess;
