import React, { useState } from 'react'
import './attachment.scoped.css'
import { useAtom } from 'jotai'
import { handleFileAddAtom } from '@/atoms/logs'
import { ReactComponent as AttachmentIcon } from '@/assets/attachment-icon-lm.svg'
import { toolKitSettingsAtom } from '@/atoms/chat'
import { useSnackbar } from 'notistack'
import Snackbar from '@/components/Snackbar/Snackbar'
import { propertiesAtom, userAtom } from '@/atoms/general'
import { getFileUploadToken } from '@/api/jwt';
import createStorageFactoryService from '../../services/storageService/storageFactoryService'
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner'

const Attachment = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [toolKitSettings] = useAtom(toolKitSettingsAtom)
  const [properties] = useAtom(propertiesAtom)
  const [user] = useAtom(userAtom)

  const { enqueueSnackbar } = useSnackbar();
  const fileInputRef = React.createRef()
  const [, addFile] = useAtom(handleFileAddAtom)
  const handleButtonClick = () => {
    fileInputRef.current.click()
  }
  const blobUrl = properties.urls?.blob_url
  const storageFactoryService = createStorageFactoryService();
  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (!file) return

    setIsLoading(true)
    const formData = new FormData();
    formData.append('file', file);
    formData.append('modelChosen', toolKitSettings.model);
    try {
      // Get token
      const tokenRes = await getFileUploadToken(file.name, 'attachment')
      if (!tokenRes) {
        console.error(`Failed to get token for file ${file.name}`)
        return null
      }

      const { fileToken, mediaKey, type } = tokenRes;

      const config = { folderId: null, folderPath: [] };
      file.mediaKey = mediaKey;
      const pathRefactored = {
        ids: [],
        names: [],
      };

      const processingData = {
        metadata: { originalname: file.name, filename: file.name },
        userId: user.id,
        docDescription: '',
        docTitle: file.name,
        chunkSizeFromUser: 0,
        overlapSizeFromUser: 0,
        embeddingModel: [],
        vectorMethods: [],
        vectorLanguage: 'en',
        folderId: config.folderId,
        pathToDocument: pathRefactored,
        isQuestionGenerator: false,
      };

      const storageService = storageFactoryService.createStorage(type);

      const uploadRes = await storageService.uploadFileToBlob({
        content: file,
        fileToken,
        mediaKey,
        file,
        blobPath: blobUrl,
        metadata: processingData,
      });

      if (!uploadRes) {
        console.error(`Failed to upload file ${file.name}`)
        return null
      }

      console.log(`mediaKey ${mediaKey} uploaded successfully`);

      addFile({ key: id, file: file, modelChosen: toolKitSettings.model, id: mediaKey });
      enqueueSnackbar('The file has been uploaded', {
        content: (key, message) => <Snackbar type={'success'} message={message} />,
      });
      console.log('Selected file:', file.name);
      console.log('toolKitSettings.model', toolKitSettings.model);
    } catch (error) {
      console.log('error', error)
      if (error.message === 'Unsupported file format') {
        enqueueSnackbar('File type is not supported', {
          content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
      } else {
        enqueueSnackbar('Oops, something went wrong. Please try again.', {
          content: (key, message) => <Snackbar type={'error'} message={message} />,
        });
      }
    } finally {
      setIsLoading(false)
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }
    }
  }

  return (
    <>
      <button onClick={handleButtonClick}>
      {isLoading ? <LoadingSpinner properties={properties} /> : <AttachmentIcon />}      </button>
      <input
        type="file"
        ref={fileInputRef}
        disabled={isLoading}
        accept=".doc, .docx, .pdf"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default Attachment
