import React, { useState, useEffect, useCallback } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import { ReactComponent as DownloadIcon } from '@/assets/chat-download-icon.svg';
import { ReactComponent as NewChatIcon } from '@/assets/new-chat-icon.svg';
import { ReactComponent as ChatIcon } from '@/assets/new-chat-icon.svg';
import { ReactComponent as CanvasIcon } from '@/assets/new-chat-icon.svg';
import StarterKit from '@tiptap/starter-kit';
import Typography from '@tiptap/extension-typography';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import TextDirection from 'tiptap-text-direction';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { all, createLowlight } from 'lowlight';
import './CanvasDisplay.css';
import { generateWordDocument } from '../../utils/documentDocxUtil';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Image from '@tiptap/extension-image';
import MenuBar from './MenuBar/MenuBar';
import { useAtom } from 'jotai';
import {
    canvasContentChatAtom,
    currentCanvasTextAtom,
    isCanvasAtom,
    isFinishCanvasModifyAtom,
} from '../../atoms/canvas';
import DropdownDownload from './DropdownDownload/DropdownDownload';
import CanvasChatButton from '../CanvasChatButton/CanvasChatButton';
import { Chart } from './CustomExtensions/ChartExtension';
import { isCollapseContentMainAtom } from '../../atoms/general';
import 'katex/dist/katex.min.css';
import { MathExtension } from './CustomExtensions/MathExtension';

export function CanvasDisplay({ onTextChange }) {
    const [width, setWidth] = useState(500);
    const [isResizing, setIsResizing] = useState(false);
    const [startX, setStartX] = useState(0);
    const [startWidth, setStartWidth] = useState(0);

    const [canvasContent, setCanvasContent] = useAtom(canvasContentChatAtom);
    const [currentCanvasText] = useAtom(currentCanvasTextAtom);
    const [isCanvasMode] = useAtom(isCanvasAtom);
    const [isMainCollapsed] = useAtom(isCollapseContentMainAtom);
    const [,setIsFinishCanvasModify] = useAtom(
        isFinishCanvasModifyAtom
    );

    const lowlight = createLowlight(all);

    const operationHandlers = {
        delete_canvas: (args) => handleDeleteCanvas(args.targets),
        modify_canvas: (args) => handleModifyCanvas(args.modifications),
        insert_canvas: (args) => handleInsertCanvas(args),
        math_canvas: (args) => handleInsertCanvas(args),
        chart_generator: (args) =>
            editor.commands.insertChart({
                chartType: args.chartType || 'line',
                chartData: args.data,
            }),
        advanced_canvas_manager: (args) => {
            if (!editor) return;
            editor.commands.setContent(currentCanvasText + args.canvas);
            onTextChange(editor.getHTML());
        },
    };

    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                table: false,
            }),
            Typography,
            Highlight,
            CodeBlockLowlight.configure({
                lowlight,
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
                alignments: ['left', 'center', 'right'],
                defaultAlignment: 'right',
            }),
            Table.configure({
                resizable: true,
            }),
            TextDirection.configure({
                types: ['heading', 'paragraph', 'listItem', 'bulletList'],
            }),
            TableRow,
            TableHeader,
            TableCell.configure({
                allowTable: false,
            }),
            Chart,
            MathExtension,
        ],
        content: currentCanvasText,
        onUpdate: ({ editor }) => {
            onTextChange(editor.getHTML());
        },
    });

    useEffect(() => {
        if (isResizing) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing, startX, startWidth]);

    useEffect(() => {
        if (!canvasContent || !editor || !Array.isArray(canvasContent)) return;

        canvasContent.forEach((toolCall) => {
            const { name: operation, arguments: args } = toolCall;

            if (operation in operationHandlers) {
                operationHandlers[operation](args);
                return;
            }
            console.warn(`Unknown canvas operation: ${operation}`);
        });

        setCanvasContent(null);
        setIsFinishCanvasModify(true);
    }, [canvasContent, editor]);

    function handleDeleteCanvas(targets) {
        if (!editor) return;

        let currentContent = editor.getHTML();

        const targetsArray = Array.isArray(targets) ? targets : [targets];

        targetsArray.forEach((target) => {
            if (currentContent.includes(target)) {
                currentContent = currentContent.replace(target, '');
            }
        });

        editor.commands.setContent(currentContent);
        onTextChange(currentContent);
    }

    function handleModifyCanvas(modifications) {
        if (!editor) return;

        let currentContent = editor.getHTML();

        modifications.forEach((modification) => {
            if (currentContent.includes(modification.target)) {
                currentContent = currentContent.replace(
                    modification.target,
                    modification.new_content
                );
            }
        });

        editor.commands.setContent(currentContent);
        onTextChange(currentContent);
    }

    function handleInsertCanvas(args) {
        if (!editor) return;

        const { content, position } = args;
        const positionType = position?.type || 'end';

        switch (positionType) {
            case 'start':
                editor.commands.insertContentAt(0, content);
                break;

            case 'before':
            case 'after': {
                if (!position.target) {
                    console.warn('Target required for before/after insertion');
                    return;
                }

                let newContent =
                    positionType === 'after'
                        ? position.target + content
                        : content + position.target;

                handleModifyCanvas([
                    { target: position.target, new_content: newContent },
                ]);
                return;
            }

            case 'end':
            default:
                const currentContent = editor.getHTML();
                editor.commands.setContent(currentContent + content);
                break;
        }

        onTextChange(editor.getHTML());
    }

    const handleMouseDown = (e) => {
        setIsResizing(true);
        setStartX(e.clientX);
        setStartWidth(width);
        e.preventDefault();
    };

    const handleMouseMove = (e) => {
        if (!isResizing) return;

        const diff = startX - e.clientX;
        const newWidth = Math.min(Math.max(300, startWidth + diff), 900);
        setWidth(newWidth);
    };

    const handleMouseUp = () => {
        setIsResizing(false);
    };

    const resetCanvas = () => {
        editor.commands.setContent('');
        onTextChange('');
    };
    const downloadCanvasDocx = async (isRTL) => {
        await generateWordDocument(editor, isRTL);
    };

    return (
        <div
            className={`canvas-panel ${
                isMainCollapsed ? 'main-collapsed' : ''
            }`}
            style={{
                flex: isMainCollapsed ? '1 1 auto' : `0 0 ${width}px`,
                width: isMainCollapsed ? 'auto' : `${width}px`,
                transition: isResizing ? 'none' : 'all 0.1s ease',
            }}
        >
            <div
                className={`resize-handle ${isResizing ? 'resizing' : ''}`}
                onMouseDown={handleMouseDown}
            />
            <div className="canvas-container">
                <div className="canvas-header">
                    {isCanvasMode && <CanvasChatButton />}

                    <div className="canvas-btns">
                        <button
                            className="new-canvas-btn"
                            onClick={resetCanvas}
                        >
                            <NewChatIcon />
                            New Canvas
                        </button>

                        <DropdownDownload
                            generateDownload={downloadCanvasDocx}
                        />
                    </div>
                </div>
                <div className="canvas-content">
                    <MenuBar editor={editor} />
                    <EditorContent
                        editor={editor}
                        spellCheck={false}
                        className="editor-content"
                    />
                </div>
            </div>
        </div>
    );
}
