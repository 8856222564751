import { atom } from 'jotai';
import { transformProviderName } from '../utils/modelsUtils';

export const modelsProvidersAtom = atom([]);

export const modelsGettersAtom = atom((get) => ({
  getFilteredModels: (type, feature) => {
    const modelsProviders = get(modelsProvidersAtom);

    // Filter and transform models
    return modelsProviders.flatMap((provider) =>
      provider.models
        .filter((model) => model.type === type && model.extra.clientFeatures.includes(feature))
        .map((model) => ({
          ...model,
          displayName: `${transformProviderName(model.name)} - ${transformProviderName(provider.name)}`,
        }))
    );
  },
  getDropdown: (type, feature) => {
    const filteredModels = get(modelsGettersAtom).getFilteredModels(type, feature);
    return filteredModels.map((model) => ({
      label: model.displayName,
      value: model.displayName,
    }));
  },
  getDefaultName: (type, feature) => {
    const filteredModels = get(modelsGettersAtom).getFilteredModels(type, feature);
    return filteredModels.length > 0 ? filteredModels[0].displayName : null;
  },
  getModelByName: (type, feature, displayName) => {
    const filteredModels = get(modelsGettersAtom).getFilteredModels(type, feature);
    return filteredModels.find((model) => model.displayName === displayName) || null;
  },
  getModelName: (type, feature, modelValue) => {
    const filteredModels = get(modelsGettersAtom).getFilteredModels(type, feature);
    const foundModel = filteredModels.find((model) => model.name.toLowerCase() === modelValue.toLowerCase());
    return foundModel ? foundModel.displayName : null;
  },
  getModelValue: (type, feature, modelDisplayName) => {
    const filteredModels = get(modelsGettersAtom).getFilteredModels(type, feature);
    const foundModel = filteredModels.find((model) => model.displayName === modelDisplayName);
    return foundModel ? foundModel.name : null;
  },
  isInputTypeEnabled: (type, feature, displayName, inputType) => {
    const model = get(modelsGettersAtom).getModelByName(type, feature, displayName);
    return model?.extra?.inputType?.includes(inputType) || false;
  },
  isOutputTypeEnabled: (type, feature, displayName, outputType) => {
    const model = get(modelsGettersAtom).getModelByName(type, feature, displayName);
    return model?.extra?.outputType?.includes(outputType) || false;
  },
}));
