export const VectorMethods = {
    TEXT_AND_IMAGE: 'text_and_image',
    TEXT: 'text',
    SEMANTIC: 'semantic',
    FLEX: 'flex',

}

export const ALL_SUPPORTED_FILE_EXTENSIONS = {
    DOC: 'doc',
    DOCX: 'docx',
    PDF: 'pdf',
    DEFAULT: 'file',
    CSV: 'csv',
    XLSX: 'xlsx',
    JSON: 'json',
    TXT: 'txt',
}

export function mapVectorMethodDisplayNameToApiName(displayName) {
    switch (displayName) {
        case 'Text & Image':
            return VectorMethods.TEXT_AND_IMAGE;
        case 'Text':
            return VectorMethods.TEXT;
        case 'Semantic':
            return VectorMethods.SEMANTIC;
        case 'Flex':
            return VectorMethods.FLEX;
        default:
            console.error(`Vector method not supported: ${displayName}`);
            return null;
    }
}

export function getVectorMethodSupportedTypes(vectorMethod) {
    const normalizedVectorMethod = mapVectorMethodDisplayNameToApiName(vectorMethod);
    // TODO: Add support for other file types
    switch (normalizedVectorMethod) {
        case VectorMethods.TEXT_AND_IMAGE:
            return [
                ALL_SUPPORTED_FILE_EXTENSIONS.DOC,
                ALL_SUPPORTED_FILE_EXTENSIONS.DOCX,
                ALL_SUPPORTED_FILE_EXTENSIONS.PDF,
            ];
        case VectorMethods.TEXT:
            return [
                ALL_SUPPORTED_FILE_EXTENSIONS.DOC,
                ALL_SUPPORTED_FILE_EXTENSIONS.DOCX,
                ALL_SUPPORTED_FILE_EXTENSIONS.PDF,
                ALL_SUPPORTED_FILE_EXTENSIONS.TXT,
                ALL_SUPPORTED_FILE_EXTENSIONS.CSV,
                ALL_SUPPORTED_FILE_EXTENSIONS.XLSX,
                ALL_SUPPORTED_FILE_EXTENSIONS.JSON,
            ];
        case VectorMethods.FLEX:
            return [
                ALL_SUPPORTED_FILE_EXTENSIONS.DOC,
                ALL_SUPPORTED_FILE_EXTENSIONS.DOCX,
                ALL_SUPPORTED_FILE_EXTENSIONS.PDF
            ];
        case VectorMethods.SEMANTIC:
            return [
                ALL_SUPPORTED_FILE_EXTENSIONS.DOC,
                ALL_SUPPORTED_FILE_EXTENSIONS.DOCX,
                ALL_SUPPORTED_FILE_EXTENSIONS.PDF,
                ALL_SUPPORTED_FILE_EXTENSIONS.TXT,
                ALL_SUPPORTED_FILE_EXTENSIONS.CSV,
                ALL_SUPPORTED_FILE_EXTENSIONS.XLSX
            ];
        default:
            console.error(`Vector method not supported: ${vectorMethod}`);
            return [];
    }
}
