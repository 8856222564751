import { useEffect, useState } from 'react';
import axios from '@/configs/axios.js';
import { useAtom } from 'jotai';
import { loginConfigAtom } from '@/atoms/general';
import { useHistory } from 'react-router-dom';
import { FirebaseProvider } from '@/components/LoginProviders/firebase/firebase';
import { ActiveDirectoryProvider } from '@/components/LoginProviders/activeDirectory/activeDirectory';
import { OktaProvider } from '@/components/LoginProviders/okta/okta';
import { userAtom } from '@/atoms/general';
import { getDocumentsAndFoldersAtom } from '@/api/documents';
import { finishedLoginAtom, loginUser } from '@/api/users';

export const Login = () => {
  const [config] = useAtom(loginConfigAtom);
  const [LoginProvider, setLoginProvider] = useState(null);
  const [, setUser] = useAtom(userAtom);
  const [, setFinishedLogin] = useAtom(finishedLoginAtom);
  const [, getDocumentsAndFolders] = useAtom(getDocumentsAndFoldersAtom);
  const navigate = useHistory();

  useEffect(() => {
    console.log('Login Provider:', config.type);

    const loginProviderMap = {
      FIREBASE: FirebaseProvider,
      ACTIVE_DIRECTORY: ActiveDirectoryProvider,
      OKTA: OktaProvider,
    };
    const selectedProvider = loginProviderMap[config.type];

    setLoginProvider(() => selectedProvider);
  }, [config]);

  const handleLogin = async (userLoginInfo) => {
    try {
      // update the header token with provider token
      axios.updateAxiosToken(userLoginInfo.providerToken);

      // login to jeen and create jeen token
      const data = await loginUser(userLoginInfo.email, config.type);
      if (!data) {
        console.log('Failed to create jeen token.');
        return false;
      }

      // destrcut params of backend response
      const { token: apiToken, user: apiUser } = data;
      setUser({
        id: apiUser.id,
        groupId: apiUser.groupId,
        email: apiUser.email,
        userName: apiUser.name,
        lang: 'english',
        token: apiToken,
        providerToken: userLoginInfo.providerToken,
        modelAvailable: apiUser.modelAvailable,
        tokenLimit: apiUser.tokenLimit,
        tokenUsed: apiUser.tokenUsed,
        wordsUsed: apiUser.wordsUsed
      });

      // update the header token with jeen token
      axios.updateAxiosToken(apiToken);
      const now = new Date().getTime();
      sessionStorage.setItem('lastAuthTimestamp', now);
      setFinishedLogin(true);

      return true;
    } catch (err) {
      console.log(err);
      navigate.push('/');
    }
  };

  const loginUserWithData = async () => {
    try {
      // access playground page and get user necessary data
      navigate.push('/playground');
      getDocumentsAndFolders();
    } catch (err) {
      console.log('Failed to login user and fetch data:', err);
    }
  };

  return LoginProvider ? (
    <LoginProvider handleLogin={handleLogin} loginUserWithData={loginUserWithData} />
  ) : (
    <div>Loading Provider...</div>
  );
};
