import React from "react";
// import { useParams } from "react-router-dom";
// import axios from "@/axiosConfig";
import { ReactComponent as ResourceLinkImg } from "@/assets/resource-link-img.svg";

import "./resourcePage.scoped.scss";
import { Modal } from '@/components/Modal/Modal';

export const ResourcePage = ({ resource, setOpenResourcePopUp }) => {
  const { title, text } = resource;
  const formattedText = text.replace(/(Question \d+:)/g, "\n$1");
  const lines = formattedText.split("\n");

  // const [resourceText, setResourceText] = useState('')
  // const [resourceTitle, setResourceTitle] = useState('')

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(`api/data/${resourceId}`)
  //       setResourceText(response.data.text)
  //       setResourceTitle(response.data.title)
  //     } catch (error) {
  //       console.error('Error fetching resource data:', error)
  //       setResourceText('Failed to load resource')
  //     }
  //   }

  //   fetchData()
  // }, [resourceId])

  const closePopUp = () => {
    setOpenResourcePopUp(false);
  };

  return (
    <Modal
      isOpen={true}
      onClose={closePopUp}
      style={{ width: '50%', gap: '15px', maxWidth: '1300px', height: '500px' }}
    >
      <div >
        <div className="title-img" style={{display:'flex', flexDirection:'row', gap: '5px', marginBottom: '10px'}}>
          <ResourceLinkImg />
          <h1>{title}</h1>
        </div>
        <p>
          {/* {text} */}
          {lines.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index !== lines.length - 1 && <br />}
            </React.Fragment>
          ))}
        </p>
      </div>
    </Modal>
  );
};
