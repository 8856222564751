import React, { useEffect, useState, useRef, useImperativeHandle } from "react";
import { useAtom } from "jotai";
import { userAtom, submitAtom } from "@/atoms/general";
import { isPreviewAtom } from "@/atoms/preview";
import { inputsAtoms } from "@/atoms/inputs";
import { toolKitSettingsAtom } from "@/atoms/chat";
import { activeLogAtom } from "@/atoms/logs";
import { prevLogIdAtom } from "@/atoms/login";
import Lottie from "react-lottie";
import animationData from "@/assets/three-dot-animation.json";
import moment from "moment";
import "moment/locale/he";
import WordImg from "@/assets/word-img.png";
import PdfImg from "@/assets/pdf-img.png";
import "./history.scoped.css";
import { htmlDecode } from "@/utils/html";
import { modelsGettersAtom } from "@/atoms/models";
import axios from "@/configs/axios.js";
import { markedDocumentsAtom } from "@/atoms/documents";
import { treeAtom } from "@/atoms/folders";
import { markFilesList } from "@/services/mark/markFilesList";
import { unmarkAll } from "@/services/mark/unmarkAll";
import { currentCanvasTextAtom } from "../../../atoms/canvas";

export const History = ({ isPageEnglish, setIsCloseToolKit }) => {
  const [inputs, setInputs] = useAtom(inputsAtoms);
  const [user, setUser] = useAtom(userAtom);
  const [isPreview, setIsPreview] = useAtom(isPreviewAtom);
  const [logSignal, setSignalLog] = useAtom(submitAtom);
  const [prevLodId, setPrevLogId] = useAtom(prevLogIdAtom);
  const [markedDocuments, setMarkedDocuments] = useAtom(markedDocumentsAtom);
  const [modelsGetters] = useAtom(modelsGettersAtom);
  const [toolkitSettings, setToolKitSettings] = useAtom(toolKitSettingsAtom);
  const [activeLog, setActiveLog] = useAtom(activeLogAtom);
  const [entireTree, setEntireTree] = useAtom(treeAtom);
  const [, setCurrentCanvasText] = useAtom(currentCanvasTextAtom);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [historyToShow, setHistoryToShow] = useState([]);
  const [filterInput, setFilterInput] = useState("");
  const [currPage, setCurrPage] = useState(1);
  const [pagesAmount, setPagesAmount] = useState();
  const isSearching = useRef();
  const [searchInput, setSearchInput] = useState("");
  let historyFiltered = [...historyToShow];

  if (searchInput.trim()) {
    historyFiltered = historyToShow.map((group) => {
      return {
        ...group,
        days: group.days.filter((day) => {
          console.log("day:", day);
          console.log(day.answer);
          console.log(day.question);
          return (
            day.question[1].value.includes(searchInput.trim()) ||
            day.answer.includes(searchInput.trim())
          );
        }),
      };
    });
  }

  useEffect(() => {
    const start = async () => {
      if (!user) {
        const loggedUser = localStorage.getItem("user");
        if (loggedUser) setUser(JSON.parse(loggedUser));
      }
      await handleFilterChange();
    };
    start();
  }, [user, filterInput, logSignal, currPage]);

  const handleFilterChange = async () => {
    clearTimeout(isSearching.current);
    if (filterInput) {
      isSearching.current = setTimeout(async () => {
        await getData();
      }, 100);
    } else {
      await getData();
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const getData = async () => {
    let historyDb = [];
    if (!user) return;

    const history = await axios.put("/api/logs/get-logs-history", {
      userId: user.id,
      pageNumber: currPage,
      filter: filterInput,
    });
    setIsInitialLoad(false);
    const safeJsonPrase = (str, defaultValue = null) => {
      if (!str || str === "undefined") return defaultValue;
      try {
        return JSON.parse(str);
      } catch {
        return defaultValue;
      }
    };
    history.data.rows.forEach((day, index) => {
      let newDays = [];
      day.rows.forEach((doc) => {
        const question = safeJsonPrase(doc.question, []);

        if (question.length > 0) {
          newDays.push({
            question,
            answer: htmlDecode(doc.answer || ""),
            title: doc.title,
            toolkitSettings: JSON.parse(doc.toolkit_settings),
            answerInEnglish: htmlDecode(doc.answer_in_english || ""),
            isShown: false,
            documentSelected:
              doc.documents_selected && doc.documents_selected.length
                ? JSON.parse(doc.documents_selected)
                : [],
            category: doc.category,
            whenAsked: doc.created_at,
            sentiment: doc.sentiment,
            time: calcDateEnglish(doc.created_at).time,
            tokenAmount: doc.token_amount,
            wordsAmount: doc.words_amount,
            isLike: doc.is_like ? doc.is_like.trim() === "true" : false,
            id: doc.id,
            apiModel: doc.api_model,
          });
        }
      });
      historyDb[index] = {
        dayFormat: calcDate(day.day),
        dayInEnglishFormat: calcDateEnglish(day.day),
        days: newDays,
      };
    });

    setPagesAmount(history.data.pagesNumber);
    setHistoryToShow([...historyDb]);
  };

  const fetchLog = async (id, date, time) => {
    sessionStorage.setItem("chat", JSON.stringify(inputs));
    sessionStorage.setItem("documents", JSON.stringify(markedDocuments));
    sessionStorage.setItem("toolkit", JSON.stringify(toolkitSettings));
    sessionStorage.setItem("log-id", JSON.stringify(prevLodId));
    sessionStorage.setItem("tree", JSON.stringify(entireTree));

    setPrevLogId(id);
    setActiveLog({
      id: id,
      day: date.dayInEnglishFormat.day,
      dayNum: date.dayInEnglishFormat.dayNum,
      month: date.dayInEnglishFormat.month,
      year: date.dayInEnglishFormat.year,
      time,
    });

    try {
      const { data } = await axios.get(`/api/logs/${id}`);
      const question = JSON.parse(data.question);
      const parsedToolkit = JSON.parse(data.toolkit_settings);
      const { model, embeddingModel } = parsedToolkit;
      const modelCompletionName = modelsGetters.getModelName(
        "completion",
        "chat",
        model
      );
      const modelEmbeddingName = modelsGetters.getModelName(
        "embedding",
        "chat",
        embeddingModel
      );
      const updatedToolkit = {
        ...parsedToolkit,
        model: modelCompletionName.length ? modelCompletionName : model,
        embeddingModel: modelEmbeddingName.length
          ? modelEmbeddingName
          : embeddingModel,
      };
      setToolKitSettings(updatedToolkit);
      setInputs(question);
      const files =
        isValidJson(data.documents_selected) &&
        data.documents_selected !== "undefined"
          ? JSON.parse(data.documents_selected)
          : [];
      let tree = unmarkAll(entireTree.files, entireTree.subfolders);

      tree = markFilesList(tree.files, tree.subfolders, files);
      setEntireTree(tree);
      setIsPreview(true);
      setCurrentCanvasText(question[0].canvasContentText);
    } catch (err) {
      console.error("Failed to fetch logs:", err.message);
    }
  };

  const calcDate = (timestamp) => {
    moment.locale("he");

    const year = moment(timestamp).format("YYYY");
    const month = moment(timestamp).format("בMMMM");
    const day = moment(timestamp).format("D");
    const time = moment(timestamp).format("HH:mm");
    const date = { year: year, month: month, day: day, time: time };
    return date;
  };
  const calcDateEnglish = (timestamp) => {
    moment.locale("en");
    const year = moment(timestamp).format("YYYY");
    const month = moment(timestamp).format("MMMM");
    const dayNum = moment(timestamp).format("D");
    const day = moment(timestamp).format("dddd");
    const time = moment(timestamp).format("HH:mm");
    const date = {
      year: year,
      month: month,
      day: day,
      dayNum: dayNum,
      time: time,
    };
    return date;
  };

  const deleteLog = async (e, logId) => {
    e.stopPropagation();
    let isWontToDelete;
    if (isPageEnglish)
      isWontToDelete = window.confirm(
        "Are you sure it will be permanently deleted?"
      );
    else isWontToDelete = window.confirm("האם ברצונך למחוק למציתות?");
    if (isWontToDelete) {
      await axios.post("/api/logs/delete", {
        logId: logId,
        userId: user.id,
      });
      setSignalLog((prev) => !prev);
    }
  };
  const isValidJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const backCurrentSession = async () => {
    if (isPreview) {
      const chat = JSON.parse(sessionStorage.getItem("chat"));
      const logId = JSON.parse(sessionStorage.getItem("log-id"));
      const documents = JSON.parse(sessionStorage.getItem("documents"));
      const toolkit = JSON.parse(sessionStorage.getItem("toolkit"));
      const tree = JSON.parse(sessionStorage.getItem("tree"));
      setPrevLogId(logId);
      setActiveLog("");
      setIsCloseToolKit(true);
      setInputs(chat);
      setEntireTree(tree);
      setToolKitSettings(toolkit);
      sessionStorage.removeItem("chat");
      sessionStorage.removeItem("documents");
      sessionStorage.removeItem("toolkit");

      setIsPreview(false);
    }
  };

  if (!user) return;
  return (
    <div>
      <input
        className="search-input"
        placeholder="Search"
        type="text"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      <h1 className="history-subheadline">30-day history</h1>

      {isInitialLoad && !activeLog ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "100px", height: "25px", overflow: "auto" }}>
            {/* loading history */}
            <Lottie options={defaultOptions} />
          </div>
        </div>
      ) : (
        <div className="ques-examples-page">
          {historyFiltered.length === 0 ||
          historyFiltered.every((group) => group.days.length === 0) ? (
            <div className="no-history-message">
              <p>Tha chat history for this user does not exists.</p>
            </div>
          ) : (
            historyFiltered.map((example, index) =>
              example.days.length === 0 ? null : (
                <div key={index} className="flex-days">
                  <p className="exact-date">
                    {example.dayInEnglishFormat.day}{" "}
                    {example.dayInEnglishFormat.dayNum}{" "}
                    {example.dayInEnglishFormat.month}
                    {", "} {example.dayInEnglishFormat.year}
                  </p>
                  {example.days.map((day, innerIndex) => (
                    <div key={day.id}>
                      {innerIndex === 0 && index === 0 ? (
                        <>
                          <button
                            onClick={backCurrentSession}
                            className={`single-ques-ans ${
                              !activeLog ? "active-log" : ""
                            }`}
                          >
                            <p className="now ltr">Now</p>
                            <div className="img-psuedo-container">
                              {day.question[1].files.length > 0 && (
                                <>
                                  {day.question[1].files.length > 1 && (
                                    <div className="files-counter">
                                      {" "}
                                      +{day.question[1].files.length - 1}
                                    </div>
                                  )}

                                  <img
                                    className="file-type-img"
                                    src={
                                      day.question[1].files[0].name
                                        .trim()
                                        .includes("pdf")
                                        ? PdfImg
                                        : WordImg
                                    }
                                    alt="word image"
                                  />
                                </>
                              )}
                            </div>
                            <div className="answer-container">
                              <p className="current-editor ltr">
                                Current version
                              </p>
                            </div>
                          </button>
                          <button
                            key={day.id}
                            onClick={() => fetchLog(day.id, example, day.time)}
                            className={`single-ques-ans ${
                              activeLog.id === day.id ? "active-log" : ""
                            }`}
                          >
                            <div />
                            <p className="history-ques-date ltr">{day.time}</p>
                            <div className="img-psuedo-container">
                              {day.question[1].files.length > 0 && (
                                <>
                                  {day.question[1].files.length > 1 && (
                                    <div className="files-counter">
                                      {" "}
                                      +{day.question[1].files.length - 1}
                                    </div>
                                  )}

                                  <img
                                    className="file-type-img"
                                    src={
                                      day.question[1].files[0].name
                                        .trim()
                                        .includes("pdf")
                                        ? PdfImg
                                        : WordImg
                                    }
                                    alt="word image"
                                  />
                                </>
                              )}
                            </div>

                            <div className="answer-container">
                              <p className="answer ltr">
                                {htmlDecode(day.title)}
                              </p>
                            </div>
                          </button>
                        </>
                      ) : (
                        <button
                          key={day.id}
                          onClick={() => fetchLog(day.id, example, day.time)}
                          className={`single-ques-ans ${
                            activeLog.id === day.id ? "active-log" : ""
                          }`}
                        >
                          <p className="history-ques-date ltr">{day.time}</p>
                          <div className="img-psuedo-container">
                            {day.question[1].files.length > 0 && (
                              <>
                                {day.question[1].files.length > 1 && (
                                  <div className="files-counter">
                                    {" "}
                                    +{day.question[1].files.length - 1}
                                  </div>
                                )}

                                <img
                                  className="file-type-img"
                                  src={
                                    day.question[1].files[0].name
                                      .trim()
                                      .includes("pdf")
                                      ? PdfImg
                                      : WordImg
                                  }
                                  alt="word image"
                                />
                              </>
                            )}
                          </div>

                          <div className="answer-container">
                            <p className="answer ltr">
                              {htmlDecode(day.title)}
                            </p>
                          </div>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              )
            )
          )}
        </div>
      )}
    </div>
  );
};
