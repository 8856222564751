import { PublicClientApplication } from '@azure/msal-browser';

class ActiveDirectoryStore {
  constructor(config) {
    if (!config) {
      throw new Error('Active directory configuration is required.');
    }
    // this.msalConfig = config;
    this.msalInstance = new PublicClientApplication(config);
  }

  async initialize() {
    try {
      await this.msalInstance.initialize();
      this.msalInstance.loginRedirect({});
    } catch (error) {
      alert('username or password are invalid');
      console.error('Login failed:', error);
      throw new Error('Invalid credentials or login issue.');
    }
  }

  async login() {
    try {
      const loginResponse = await this.msalInstance.handleRedirectPromise();

      if (loginResponse) {
        const account = this.msalInstance.getAccountByHomeId(loginResponse.account.homeAccountId);
        this.msalInstance.setActiveAccount(account);
        if (!account) {
          throw new Error('No account found');
        }

        const accessTokenResponse = await this.msalInstance.acquireTokenSilent({
          scopes: ['https://graph.microsoft.com/.default'],
          account: account,
        });

        return { account, accessToken: accessTokenResponse.accessToken };
      } else {
        console.log('No login response');
        return;
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  }
}

export default ActiveDirectoryStore;
