import React from 'react'

function LoadingSpinner({ properties }) {
  const spinnerColor = properties?.colors?.main_colors['--main-clr'] || 'rgba(249, 109, 223, 1)'
  
  return (
    <svg 
      width="24" 
      height="24" 
      viewBox="0 0 24 24"
      style={{
        animation: 'spin 1s linear infinite',
      }}
    >
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={spinnerColor}
        strokeWidth="2"
        fill="none"
        strokeDasharray="30 50"
      />
    </svg>
  )
}

export default LoadingSpinner