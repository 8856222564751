import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import createAuthProviderService from '@/services/authProviderService';
import { finishedLoginAtom } from '@/api/users';
import Login from '@/components/Login/Login';
import { loginConfigAtom } from '@/atoms/general';

export const FirebaseProvider = ({ handleLogin, loginUserWithData }) => {
  const [config] = useAtom(loginConfigAtom);
  const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });
  const navigate = useHistory();

  const authProviderService = createAuthProviderService();
  const provider = authProviderService.createProvider(config.type, config.clientPayload);

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setLoginInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleLoginProvider = async (e) => {
    console.log('handle login provider');
    e.preventDefault();

    try {
      const fireBaseResponse = await provider.login({
        email: loginInfo.email,
        password: loginInfo.password,
      });
      if (fireBaseResponse) {
        const fbUser = fireBaseResponse.user;
        if (fbUser) {

          // Login to jeen and create jeen token
          const isLogin = await handleLogin({ email: fbUser.email, providerToken: fbUser.accessToken });
          if (isLogin) {
            loginUserWithData();
          }
        }
      }
    } catch (err) {
      console.log('Error logging in with firebase:', err);
      navigate.push('/');
    }
  };

  return <Login loginForm={loginInfo} handleLogin={handleLoginProvider} handleChange={handleChange} />;
};
