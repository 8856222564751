import "./toolkit.scoped.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as ModelIcon } from "@/assets/model-icon-lm.svg";
import { ReactComponent as LangIcon } from "@/assets/language-icon-lm.svg";
import { ReactComponent as ScopeIcon } from "@/assets/scope-icon-lm.svg";
import { ReactComponent as AnswersIcon } from "@/assets/answers-icon-lm.svg";
import { ReactComponent as DataIcon } from "@/assets/data-icon-lm.svg";
import { ReactComponent as TypeIcon } from "@/assets/type-icon-lm.svg";
import { ReactComponent as PromptsIcon } from "@/assets/prompts_icon_toolkit.svg";
import { ReactComponent as TemplateIcon } from "@/assets/template-icon-lm.svg";
import { ReactComponent as TemperatureIcon } from "@/assets/temperature-icon-lm.svg";
import { ReactComponent as MinusIcon } from "@/assets/minus-icon-lm.svg";
import { ReactComponent as PlusIcon } from "@/assets/plus-icon-lm.svg";
import AutoComplete from "@/components/AutoComplete/AutoComplete";
import { toolKitSettingsAtom } from "@/atoms/chat";
import { useAtom } from "jotai";
import { isMobileAtom, propertiesAtom, userAtom } from "@/atoms/general";
import { useEffect, useRef, useState } from "react";
import { markedDocumentsAtom, documentsOpenPopUpAtom } from "@/atoms/documents";
import { promptsToShowUserAtom, markedPromptsAtom } from "@/atoms/prompts";
import { modelsGettersAtom } from "@/atoms/models";
import { ReactComponent as VectorType } from "@/assets/toolkit-vector-type.svg";

export const ToolKit = ({ isCloseToolKit }) => {
  const [toolKitSettings, setToolKitSettings] = useAtom(toolKitSettingsAtom);
  const [properties] = useAtom(propertiesAtom);
  const [markedDocuments] = useAtom(markedDocumentsAtom);
  const [promptsToShowUser] = useAtom(promptsToShowUserAtom);
  const [isMobile] = useAtom(isMobileAtom);
  const [user] = useAtom(userAtom);
  const [markedPrompts, setMarkedPrompts] = useAtom(markedPromptsAtom);
  const [toggleTabs, setToggleTabs] = useState("control-panel");
  const [activeTooltip, setActiveTooltip] = useState({ key: null });
  const timerIdRef = useRef(null);
  const [modelsGetters] = useAtom(modelsGettersAtom);
  const [userModels, setUserModels] = useState([]);
  const dropdownEmbeddingOptions = modelsGetters.getDropdown(
    "embedding",
    "chat"
  );
  const dropdownCompletionOptions = modelsGetters.getDropdown(
    "completion",
    "chat"
  );
  const defaultEmbeddingModel = modelsGetters.getModelName(
    "embedding",
    "chat",
    "text-embedding-ada-002"
  );
  const [userModelsTooltip, setUserModelsTooltip] = useState([]);

  useEffect(() => {
    try {
      if (dropdownCompletionOptions.length) {
        const userToolkitModels = user.modelAvailable.map((model) => {
          const modelName = modelsGetters.getModelName(
            "completion",
            "chat",
            model
          );
          if (modelName && modelName) {
            return {
              label: modelName,
              value: modelName,
            };
          }
        });
        if (userToolkitModels && userToolkitModels.length) {
          setUserModels(userToolkitModels);
          // } else {
          //   setUserModels(dropdownCompletionOptions);
        }
      }
    } catch (error) {
      console.log("Failed fetching user chat models:", error);
    }
  }, [dropdownCompletionOptions.length, user.modelAvailable]);

  useEffect(() => {
    // Update model setting
    const updateCompletionModel = () => {
      if (userModels.length) {
        const modelExists = userModels.some(
          (model) => model.value === toolKitSettings.model
        );
        if (!toolKitSettings.model || !modelExists) {
          const newModel = userModels[0].label;
          setToolKitSettings((prev) => ({ ...prev, model: newModel }));
          console.log(`Chat Model: ${newModel}`);
        }
      }
    };
    // Update embedding model setting
    const updateEmbeddingModel = () => {
      if (dropdownEmbeddingOptions.length) {
        const embeddingExists = dropdownEmbeddingOptions.some(
          (model) => model.value === toolKitSettings.embeddingModel
        );
        if (!toolKitSettings.embeddingModel || !embeddingExists) {
          const newEmbeddingModel = defaultEmbeddingModel;
          setToolKitSettings((prev) => ({
            ...prev,
            embeddingModel: newEmbeddingModel,
          }));
          console.log(`Chat EmbeddingModel: ${newEmbeddingModel}`);
        }
      }
    };

    if (toolKitSettings.model === "") {
      updateCompletionModel();
    } else {
      // const modelName = modelsGetters.getModelName('completion', 'chat', toolKitSettings.model);
      // if (modelName) setToolKitSettings((prev) => ({ ...prev, model: modelName }));
    }
    if (toolKitSettings.embeddingModel === "") {
      updateEmbeddingModel();
    } else {
      // const modelName = modelsGetters.getModelName('embedding', 'chat', toolKitSettings.embeddingModel);
      // if (modelName) setToolKitSettings((prev) => ({ ...prev, embeddingModel: modelName }));
    }
  }, [userModels, dropdownEmbeddingOptions]);

  useEffect(() => {
    try {
      if (dropdownCompletionOptions.length) {
        // Get all available models data
        const modelsData = modelsGetters.getFilteredModels(
          "completion",
          "chat"
        );

        // Map only the models available to the user
        const userToolkitModels = user.modelAvailable
          .map((modelName) => {
            const modelData = modelsData.find(
              (model) => model.name === modelName
            );
            if (modelData) {
              return {
                name: modelData.name,
                displayName: modelData.displayName,
                tooltip: modelData.extra.tooltip,
              };
            }
            return null;
          })
          .filter(Boolean); // Remove null entries

        if (userToolkitModels.length) {
          setUserModelsTooltip(userToolkitModels);
        }
      }
    } catch (error) {
      console.log("Failed fetching user chat models tooltip:", error);
    }
  }, [dropdownCompletionOptions.length, user.modelAvailable]);

  const [disableSettings, seDisabledSettings] = useState({
    model: {
      enabled: true,
    },
    translate: {
      enabled: true,
    },
    scope: {
      enabled: true,
    },
    answers: {
      enabled: true,
    },
    temperature: {
      enabled: true,
    },
    typing: {
      enabled: true,
    },
    data: {
      enabled: true,
    },
  });

  useEffect(() => {
    const setDisableSettings = () => {
      if (properties && properties.toolkit) {
        seDisabledSettings(properties.toolkit);
      }
    };
    setDisableSettings();
  }, [properties]);

  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }
    };
  }, []);

  const activateControl = () => {
    setToggleTabs("control-panel");
  };
  const activateHistory = () => {
    setToggleTabs("history");
  };
  const is_selectedDocuments = () => {
    return markedDocuments.length > 0;
  };
  const settingsArr = [
    {
      name: "model",
      options: [...userModels],
      enabled: disableSettings["model"].enabled,
    },
    {
      name: "chatLang",
      options: [
        { label: "English", value: "english" },
        { label: "Hebrew", value: "hebrew" },
        { label: "None", value: "none" },
      ],
      enabled: disableSettings["translate"].enabled,
    },
    {
      name: "scope",
      options: [
        { label: "Long answer", value: true },
        { label: "Short answer", value: false },
      ],
      enabled: disableSettings["scope"].enabled,
    },
    {
      name: "sources",
      options: [
        { label: "Multiple", value: true },
        { label: "Single", value: false },
      ],
      enabled: disableSettings["answers"].enabled,
    },
    {
      name: "typing",
      options: [
        { label: "Effect", value: true },
        { label: "No effect", value: false },
      ],
      enabled: disableSettings["typing"].enabled,
    },
    {
      name: "template",
      options: [{ label: "None", value: "none" }],
    },
    {
      name: "temperature",
      label: "Temperature",
      enabled: disableSettings["temperature"].enabled,
    },
    {
      name: "question_choice",
      options: [
        { label: "Vectors", value: "Vectors" },
        { label: "Q&A", value: "Q&A" },
      ],
      enabled: disableSettings["data"].enabled,
    },
    {
      name: "embeddingModel",
      options: [...dropdownEmbeddingOptions],
    },
  ];
  const updateToolKit = (setting) => {
    const { name, value } = setting;
    if (name === "question_choice" && value && value.length === 0) return;
    setToolKitSettings((prev) => ({ ...prev, [name]: value }));
  };

  const handlePromptSelect = ({ target }) => {
    const { value } = target;

    let promptsAfterDelete = removePromptsByTitles(value);

    const promptsChosen = findPromptsByTitles(promptsAfterDelete);

    setMarkedPrompts([...promptsChosen]);
  };

  // Function to find prompts by titles
  function findPromptsByTitles(titles) {
    return promptsToShowUser.filter((prompt) => {
      return titles.some((title) => {
        if (typeof title === "string") {
          return prompt.title === title;
        } else if (
          typeof title === "object" &&
          title !== null &&
          "title" in title
        ) {
          return prompt.title === title.title;
        }
        return false;
      });
    });
  }

  function removePromptsByTitles(titles) {
    let promptTitle = titles.pop();
    let isFound = false;
    let newPrompts = titles.filter((prompt) => {
      console.log("====================================");
      console.log(prompt, "prompt");
      console.log("====================================");
      if (prompt.title !== promptTitle) return true;
      else {
        isFound = true;
        return false;
      }
    });

    if (!isFound) newPrompts.push(promptTitle);

    return newPrompts;
  }

  const raiseTemperature = () => {
    setToolKitSettings((prev) => {
      return {
        ...prev,
        temperature:
          prev.temperature < 10 ? prev.temperature + 1 : prev.temperature,
      };
    });
  };
  const lowerTemperature = () => {
    setToolKitSettings((prev) => {
      return {
        ...prev,
        temperature:
          prev.temperature > 0 ? prev.temperature - 1 : prev.temperature,
      };
    });
  };

  const showTooltip = (key) => {
    // Clear any existing timer
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }
    timerIdRef.current = setTimeout(() => {
      setActiveTooltip({ key: key });
    }, 500); // Set the delay here

    // setActiveTooltip({ key: null })
  };

  const hideTooltip = () => {
    if (timerIdRef.current) {
      clearTimeout(timerIdRef.current);
    }
    setActiveTooltip({ key: null });
  };
  return (
    <div className={`tool-kit ${isCloseToolKit ? "closed-toolkit" : ""} `}>
      {!isCloseToolKit ? (
        <div className="toolkit-flex">
          <div className="checkboxes-container">
            <div className="tool-tip-container">
              <div
                className={
                  activeTooltip.key === "model" ? "tooltip show" : "tooltip"
                }
              >
                Choose your preferred language model for tailored responses.
              </div>

              <div
                onMouseEnter={() => showTooltip("model")}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <ModelIcon className="icon-clr" /> Model{" "}
                </div>

                {/* <Select
                  className="select-box-tool-box"
                  size="small"
                  id="Model"
                  value={toolKitSettings.model || ''}
                  label="Model"
                  name="model"
                  onChange={(e) => {
                    updateToolKit(e.target);
                  }}
                  disabled={dropdownCompletionOptions.length === 0}
                >
                  {settingsArr[0].options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select> */}

                <AutoComplete
                  className="select-box-tool-box"
                  size="small"
                  value={toolKitSettings.model}
                  name="model"
                  updateValue={updateToolKit}
                  disabled={dropdownCompletionOptions.length === 0}
                  options={settingsArr?.[0]?.options?.map((option) => {
                    const tooltipData = userModelsTooltip?.find(
                      (model) =>
                        model.name === option.value ||
                        model.displayName === option.label
                    );
                    return {
                      ...option,
                      tooltip: tooltipData?.tooltip,
                      displayName: tooltipData?.displayName || option.label,
                    };
                  }) || []}                  
                >
                  {settingsArr[0].options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </AutoComplete>
              </div>
            </div>
            <div className="tool-tip-container">
              <div
                className={
                  activeTooltip.key === "translate" ? "tooltip show" : "tooltip"
                }
              >
                Select your desired language for system responses.
              </div>

              <div
                onMouseEnter={() => showTooltip("translate")}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <LangIcon className="icon-clr" /> Translate{" "}
                </div>

                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="Model"
                  value={toolKitSettings.chatLang || "none"}
                  label="Model"
                  name="chatLang"
                  onChange={(e) => {
                    updateToolKit(e.target);
                  }}
                >
                  {settingsArr[1].options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <hr className="seperator" />
          </div>

          <div className="checkboxes-container">
            {!isMobile && (
              <div className="tool-tip-container">
                {" "}
                <div
                  className={
                    activeTooltip.key === "scope" ? "tooltip show" : "tooltip"
                  }
                >
                  Control the length of responses - short or detailed.
                </div>
                <div
                  onMouseEnter={() => showTooltip("scope")}
                  onMouseLeave={hideTooltip}
                  className={`flex-dropdown-and-label ${(!is_selectedDocuments() ||
                    !settingsArr[2].enabled) &&
                    "opacity"}`}
                >
                  <div className="label-icon-container">
                    <ScopeIcon className="icon-clr" /> Scope{" "}
                  </div>

                  <Select
                    className="select-box-tool-box"
                    size="small"
                    id="Model"
                    disabled={
                      !is_selectedDocuments() || !settingsArr[2].enabled
                    }
                    value={toolKitSettings.isLongAnswer || false}
                    label="Model"
                    name="isLongAnswer"
                    onChange={(e) => {
                      updateToolKit(e.target);
                    }}
                  >
                    {settingsArr[2].options.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            {!isMobile && (
              <div className="tool-tip-container">
                <div
                  className={
                    activeTooltip.key === "answers" ? "tooltip show" : "tooltip"
                  }
                >
                  Get either one most accurate answer or multiple possible
                  answers arranged based on relevance to your query.
                </div>

                <div
                  onMouseEnter={() => showTooltip("answers")}
                  onMouseLeave={hideTooltip}
                  className={`flex-dropdown-and-label ${(!is_selectedDocuments() ||
                    !settingsArr[3].enabled) &&
                    "opacity"}`}
                >
                  <div className="label-icon-container">
                    <AnswersIcon className="icon-clr" /> Answers{" "}
                  </div>

                  <Select
                    className="select-box-tool-box"
                    size="small"
                    id="Model"
                    disabled={
                      !is_selectedDocuments() || !settingsArr[3].enabled
                    }
                    value={toolKitSettings.isMultiple || false}
                    label="Model"
                    name="isMultiple"
                    onChange={(e) => {
                      updateToolKit(e.target);
                    }}
                  >
                    {settingsArr[3].options.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            )}

            <div className="tool-tip-container">
              <div
                className={
                  activeTooltip.key === "temperature"
                    ? "tooltip show"
                    : "tooltip"
                }
              >
                Adjust the creativity level of the bot's responses. With 0, the
                bot won't invent details at all and with 10, it'll be highly
                creative.
              </div>
              <div
                onMouseEnter={() => showTooltip("temperature")}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <TemperatureIcon className="icon-clr" /> Temperature
                </div>

                <div className="temperature-input-container">
                  <button onClick={lowerTemperature}>
                    <MinusIcon />
                  </button>
                  <div className="temperature">
                    {toolKitSettings.temperature}
                  </div>
                  <button onClick={raiseTemperature}>
                    <PlusIcon />
                  </button>
                </div>
              </div>
            </div>
            <hr className="seperator" />
          </div>

          <div className="checkboxes-container">
            <div className="tool-tip-container">
              <div
                className={
                  activeTooltip.key === "typing" ? "tooltip show" : "tooltip"
                }
              >
                Choose between a typing effect or instant complete answers.
              </div>
              <div
                onMouseEnter={() => showTooltip("typing")}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <TypeIcon className="icon-clr" />
                  Typing{" "}
                </div>

                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="Model"
                  value={toolKitSettings.isTypingEffect || false}
                  label="Model"
                  name="isTypingEffect"
                  onChange={(e) => {
                    updateToolKit(e.target);
                  }}
                >
                  {settingsArr[4].options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div
              // onMouseEnter={() => showTooltip('question_choice')}
              // onMouseLeave={hideTooltip}
              className={`flex-dropdown-and-label ${(!is_selectedDocuments() ||
                !settingsArr[7].enabled) &&
                "opacity"}`}
            >
              <div className="label-icon-container">
                <DataIcon className="icon-clr" /> Data
              </div>

              <Select
                className="select-box-tool-box"
                size="small"
                id="question_choice"
                disabled={!is_selectedDocuments() || !settingsArr[7].enabled}
                value={
                  [...toolKitSettings.question_choice] || ["Vectors", "Q&A"]
                }
                label="question_choice"
                name="question_choice"
                multiple={true}
                onChange={(e) => {
                  updateToolKit(e.target);
                }}
                input={
                  <OutlinedInput
                    id="select-multiple-checkbox"
                    label="Validations"
                  />
                }
                renderValue={(selected) =>
                  selected
                    .map(
                      (value) =>
                        settingsArr[7].options.find(
                          (option) => option.value === value
                        ).label
                    )
                    .join(", ")
                }
              >
                {settingsArr[7].options.map((option, index) => (
                  <MenuItem key={index} value={option.value}>
                    <Checkbox
                      checked={
                        toolKitSettings.question_choice.indexOf(option.value) >
                        -1
                      }
                    />
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="tool-tip-container">
              <div
                className={
                  activeTooltip.key === "embedding" ? "tooltip show" : "tooltip"
                }
              >
                Choose your preferred embedding model.
              </div>
              <div
                onMouseEnter={() => showTooltip("embedding")}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <VectorType className="icon-clr" />
                  Embedding
                </div>

                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="embeddingModel"
                  value={toolKitSettings.embeddingModel || ""}
                  label="embeddingModel"
                  name="embeddingModel"
                  onChange={(e) => {
                    updateToolKit(e.target);
                  }}
                  disabled={
                    !is_selectedDocuments() ||
                    dropdownEmbeddingOptions.length === 0
                  }
                >
                  {settingsArr[8].options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
            <div className="flex-dropdown-and-label-last">
              {/* <div className="label-icon-container"> 
           <TemplateIcon className='icon-clr' /> Template </div>

            <Select
              className="select-box-tool-box-last"
              size="small"
              id="Model"
              value={toolKitSettings.template||'none'}
              label="Model"
              name="template"
              onChange={(e) => {
                updateToolKit(e.target);
              }}
       
            >
              {settingsArr[5].options.map((option, index) => (
                <MenuItem key={index}  value={option.value}>{option.label}</MenuItem>
              ))}
            </Select> */}
            </div>
          </div>

          <hr className="seperator" />
          <div className="checkboxes-container">
            <div className="tool-tip-container">
              <div
                className={
                  activeTooltip.key === "prompt" ? "tooltip show" : "tooltip"
                }
              >
                Choose between a prompt.
              </div>
              <div
                onMouseEnter={() => showTooltip("prompt")}
                onMouseLeave={hideTooltip}
                className="flex-dropdown-and-label"
              >
                <div className="label-icon-container">
                  <PromptsIcon className="icon-clr" />
                  Prompts
                </div>
                {/* 
                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="validations_selected"
                  value={[...toolKitSettings.validations_selected]||[]}
                  label="validations_selected"
                  name="validations_selected"
                  multiple={true}
                  onChange={(e) => {
                    updateToolKit(e.target);
                  }}
                  input={<OutlinedInput id="select-multiple-checkbox" label="Validations" />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (value) => settingsArr[5].options.find((option) => option.value === value).label
                      )
                      .join(', ')
                  }
                >
                  {settingsArr[5].options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      <Checkbox
                        checked={toolKitSettings.validations_selected.indexOf(option.value) > -1}
                      />
                      {option.label}
                    </MenuItem>
                  ))}
                </Select> */}

                <Select
                  className="select-box-tool-box"
                  size="small"
                  id="Model"
                  value={markedPrompts || []}
                  label="Model"
                  name="prompts"
                  multiple={true}
                  onChange={handlePromptSelect}
                  input={
                    <OutlinedInput
                      id="select-multiple-checkbox"
                      label="Validations"
                    />
                  }
                  renderValue={(selected) =>
                    selected
                      .map(
                        (value) =>
                          promptsToShowUser.find(
                            (option) => option.title === value.title
                          ).title
                      )
                      .join(", ")
                  }
                >
                  {promptsToShowUser.map((option, index) => (
                    <MenuItem key={index} value={option.title}>
                      <Checkbox
                        checked={
                          markedPrompts.filter(
                            (prompt) => prompt.title === option.title
                          ).length > 0
                        }
                      />
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </div>
          <div className="flex-dropdown-and-label-last" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
