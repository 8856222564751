import { atom } from 'jotai'
import { fetchEnabledFeaturesFromAPI } from '../api/features';
import { modelsProvidersAtom } from './models';

export const isChatEnabledAtom = atom(true)
export const isAssistantEnabledAtom = atom(true)

export const enabledFeaturesAtom = atom({
  isChatEnabledAtom: true,
  isAssistantEnabledAtom: true,
  isDataEnabledAtom: true,
  isPromptsEnabledAtom: true,
  isAdminEnabledAtom: true,
  isaAgentEnabledAtom: false,
  isVoiceEnabledAtom: false,
})

export const fetchEnabledFeaturesAtom = atom(null, async (get, set) => {
  try {
    const modelsProviders = get(modelsProvidersAtom);

    // Fetch enabled features
    const enabledFeatures = await fetchEnabledFeaturesFromAPI();

    // Extract `modelsProvidersAtom` and other features
    const { models_providers, ...otherFeatures } = enabledFeatures;
    // Update atoms
    set(enabledFeaturesAtom, {
      isChatEnabledAtom: otherFeatures.enable_chat,
      isAssistantEnabledAtom: otherFeatures.enable_assistant,
      isDataEnabledAtom: otherFeatures.enable_data,
      isPromptsEnabledAtom: otherFeatures.enable_prompts,
      isAdminEnabledAtom: otherFeatures.enable_admin,
      isaAgentEnabledAtom: otherFeatures.enable_agents,
      isVoiceEnabledAtom: otherFeatures.enable_voice,
    });

    set(modelsProvidersAtom, models_providers);
  } catch (error) {
    console.error('Error fetching and setting enabled features:', error);
  }
});