export const transformProviderName = (name) =>
  name
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

// Helper function to get the default model
export const getDefaultModel = (modelsProviders, type, clientFeature) => {
  const model = modelsProviders.flatMap((provider) =>
    provider.models
      .filter((model) => model.type === type && model.extra.clientFeatures.includes(clientFeature))
      .map((model) => ({
        ...model,
        displayName: `${transformProviderName(provider.name)} - ${model.name}`,
      }))
  )[0];

  return model ? model.displayName : null;
};
