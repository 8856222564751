import './login.scoped.scss';
import React, { useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { userAtom, propertiesAtom } from '@/atoms/general';
import { isMobileAtom } from '@/atoms/general';
import { useAtom } from 'jotai';
import axios from '@/configs/axios.js';
import { finishedLoginAtom } from '@/api/users';
import { getDocumentsAndFoldersAtom } from '@/api/documents';
import { useOktaAuth } from '@okta/okta-react';
import { LOGIN_CONFIG_TYPE } from '@/constants/enums';

export const LoginOkta = () => {
  const [, setUser] = useAtom(userAtom);
  const [finishedLogin, ] = useAtom(finishedLoginAtom);
  const [isMobile] = useAtom(isMobileAtom);
  const [properties] = useAtom(propertiesAtom);
  const [logoUrls, setLogoUrls] = useState({});
  const [, getDocumentsAndFolders] = useAtom(getDocumentsAndFoldersAtom);
  const navigate = useHistory();
  const { oktaAuth, authState } = useOktaAuth();

  useMemo(() => {
    setLogoUrls({
      login_logo: properties.urls?.logo_urls['login-logo'],
      credit_logo: properties.urls?.logo_urls['credit-logo'],
    });
  }, [properties]);

  if (!authState || !oktaAuth) {
    return <div>Loading...</div>;
  }
  if (authState.isAuthenticated) {
    const makeSureLogin = async () => {
      const okta = JSON.parse(localStorage.getItem('okta-token-storage'));
      if (okta && okta.accessToken) {
        const userMail = okta.accessToken.claims.sub;

        if (okta.accessToken.expiresAt < Date.now() / 1000) {
          localStorage.clear();
          navigate('/');
        }
        axios.updateAxiosToken(okta.accessToken);
        const { data } = await axios.put('/api/user/login', {
          email: userMail
        });
        const { token, user, isAllowed } = data;

        setUser({
          id: user.id,
          email: userMail,
          userName: userMail,
          lang: 'english',
          isAllowed: isAllowed,
          token,
        });
        axios.updateAxiosToken(token);
        sessionStorage.setItem('tokenForGeko', token);

        sessionStorage.setItem(
          'user',
          JSON.stringify({
            id: user.id,
            email: userMail,
            userName: userMail,
            lang: 'english',
            isAllowed: isAllowed,
            token,
          })
        );
        navigate.push('/playground');
      }
    };
    makeSureLogin();
  }

  const handleLogin = (ev) => {
    ev.preventDefault();
    oktaAuth.signInWithRedirect();
  };
  if (finishedLogin) {
    navigate.push('/playground');
    getDocumentsAndFolders();
    // return <Redirect push to='/playground' />
  }

  return (
    <div className="login-page">
      <div className="modal-full-screen">
        <div
          className="modal-container"
          style={isMobile ? { width: '90%', overflowY: 'visible' } : { width: '55%', overflowY: 'auto' }}
        >
          <div className="modal-upper">
            {logoUrls.login_logo ? (
              <img
                src={logoUrls.login_logo}
                alt="onebi logo"
                style={isMobile ? { width: '180px' } : { width: '253px' }}
              />
            ) : (
              <div className="loading-icon-login">
                <CircularProgress
                  sx={{
                    position: 'absolute',
                    left: '46%',
                    color: properties?.colors?.main_colors['--main-clr'] || `red`,
                  }}
                  size="50px"
                />
              </div>
            )}
          </div>
          <div style={isMobile ? { width: '300px' } : { width: 'auto' }}>
            <h2>Jeen.AI Your AI environment</h2>
          </div>

          <div className="form-container">
            <form onSubmit={handleLogin}>
              <button onClick={handleLogin} className="login-btn">
                Login
              </button>
            </form>
          </div>
        </div>
        <div className="bottom-credit" style={isMobile ? { left: '30%' } : { left: '45%' }}>
          <h4>Created By</h4>
          {logoUrls.credit_logo ? (
            <img src={logoUrls.credit_logo} alt="onebi logo" />
          ) : (
            <div className="loading-icon-credit">
              <CircularProgress
                sx={{
                  position: 'absolute',
                  left: '100%',
                  bottom: '0%',
                  color: properties?.colors?.main_colors['--main-clr'] || `rgba(249, 109, 223, 1)`,
                }}
                size="20px"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
