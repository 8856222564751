import './password-input.scoped.css';
import { useState } from 'react';
import { ReactComponent as ShowPasswordIcon } from '@/assets/show-password-icon.svg';
import { ReactComponent as HidePasswordIcon } from '@/assets/hide-password-icon.svg';

const PasswordInput = ({ value, setValue }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="password-container">
      <input
        className="password-input"
        name="password"
        type={showPassword ? 'text' : 'password'}
        placeholder=""
        value={value || ''}
        onChange={setValue}
        autoComplete="false"
      />
     {value.length > 0 && <span onClick={() => setShowPassword((prev) => !prev)}>
        {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
      </span>}
    </div>
  );
};

export default PasswordInput;
