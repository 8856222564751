import React, { useEffect, useRef } from 'react';
import { NodeViewWrapper } from '@tiptap/react';
import Chart from 'chart.js/auto';

export const ChartComponent = ({ node }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        const ctx = chartRef.current?.getContext('2d');
        if (!ctx || !node.attrs.chartContent) return;

        try {
            const chartData = JSON.parse(node.attrs.chartContent);

            chartInstance.current = new Chart(ctx, {
                type: chartData.type,
                data: chartData.data,
                options: chartData.options || {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                    },
                },
            });
        } catch (error) {
            console.error('Error creating chart:', error);
        }

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [node.attrs.chartContent]);

    return (
        <NodeViewWrapper className="chart-wrapper">
            <div style={{ width: '100%', height: '400px', padding: '1rem' }}>
                <canvas ref={chartRef} />
            </div>
        </NodeViewWrapper>
    );
};
