import './table-inner-row.scoped.css';
import { Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { groupsAtomWithUpdates } from '@/atoms/admin';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAtom } from 'jotai';
import { useSnackbar } from 'notistack';
import Snackbar from '@/components/Snackbar/Snackbar';
import { modelsGettersAtom } from '@/atoms/models';
import { updateUserToken, updateUserModel } from '@/api/admin';
import { userAtom } from '@/atoms/general';
import Tooltip from '@/components/Tooltip/Tooltip';

export const TableInnerRow = ({ user, group, settings }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [, setGroups] = useAtom(groupsAtomWithUpdates);
  const [loggedUser, setUser] = useAtom(userAtom);
  const [modelsGetters] = useAtom(modelsGettersAtom);

  const getToken = (token) => {
    const chosen = settings[1].options.find((option) => option.value === token);
    return chosen;
  };

  const getModelNameByValue = (modelsArray) => {
    const chosen = modelsArray.map((model) => {
      if (model && model.length) {
        const foundModel = modelsGetters.getModelName('completion', 'chat', model);
        if (foundModel && foundModel.length) {
          return foundModel;
        } else {
          return model;
        }
      }
    });
    return chosen;
  };

  const getModelValueByName = (modelsArray) => {
    const chosen = modelsArray.map((model) => {
      if (model && model.length) {
        const foundModel = modelsGetters.getModelValue('completion', 'chat', model);
        if (foundModel && foundModel.length) {
          return foundModel;
        } else {
          return model;
        }
      }
    });
    return chosen;
  };

  const handleTokenChange = async (event) => {
    const currentTokenValue = user.token_limit;
    const newTokenValue = event.target.value;
    setGroups({
      type: 'updateUserToken',
      payload: { groupId: user.group_id, userId: user.id, val: newTokenValue },
    });
    const isUserUpdate = updateUserToken({ userId: user.id, token: newTokenValue });
    if (isUserUpdate) {
      if (loggedUser.id === user.id) {
        setUser((prev) => ({ ...prev, tokenLimit: newTokenValue }));
      }
      enqueueSnackbar('User Token Has been Updated.', {
        content: (key, message) => <Snackbar type={'success'} message={message} />,
      });
    } else {
      setGroups({
        type: 'updateUserToken',
        payload: { groupId: user.group_id, userId: user.id, val: currentTokenValue },
      });
      enqueueSnackbar('Failed to Update User Token.', {
        content: (key, message) => <Snackbar type={'error'} message={message} />,
      });
    }
  };

  const handleModelChange = async (event) => {
    const currnetModelValue = user.model;
    const newModelsValue = getModelValueByName(event.target.value);
    setGroups({
      type: 'updateUserModel',
      payload: { groupId: user.group_id, userId: user.id, val: newModelsValue },
    });
    const isUserUpdate = updateUserModel({ userId: user.id, model: newModelsValue });
    if (isUserUpdate) {
      if (loggedUser.id === user.id) {
        setUser((prev) => ({ ...prev, modelAvailable: newModelsValue }));
      }
      enqueueSnackbar('User Models Has been Updated.', {
        content: (key, message) => <Snackbar type={'success'} message={message} />,
      });
    } else {
      setGroups({
        type: 'updateUserModel',
        payload: { groupId: user.group_id, userId: user.id, val: currnetModelValue },
      });
      enqueueSnackbar('Failed to Update User Models.', {
        content: (key, message) => <Snackbar type={'error'} message={message} />,
      });
    }
  };

  return (
    <tr>
      <td />
      <td style={{ lineHeight: 'normal' }}>{group.group_name}</td>
      <td
        style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          paddingLeft: '10px',
          dir: 'ltr',
          lineHeight: 'normal',
        }}
      >
        <Tooltip value={user.name}>{user.name || 'Null'}</Tooltip>
      </td>
      <td style={{ overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 'normal' }}>
        <Tooltip value={user.email}>
          <span>{user.email || 'Null'}</span>
        </Tooltip>
      </td>
      <td style={{ textAlign: 'center', paddingRight: '30px', lineHeight: 'normal' }}>
        {user.title || 'Null'}
      </td>
      <td style={{ display: 'flex', justifyContent: 'center', paddingRight: '30px' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {user.token_limit > group.default_max_tokens_per_user && (
            <div
              style={{
                marginLeft: '0px',
                height: '0px',
                marginTop: '10px',
              }}
            >
              <span
                style={{
                  color: `var(--red-clr)`,
                  marginTop: '10px',
                  fontSize: '32px',
                  marginLeft: '-20px',
                }}
              >
                *
              </span>
            </div>
          )}
          <div>
            {user.token_limit !== null ? (
              <div>
                <Select
                  className="select-box-tool-box"
                  size="medium"
                  id={user.id}
                  value={getToken(user.token_limit).value}
                  style={{ width: '100px' }}
                  label="Token"
                  name="token"
                  onChange={handleTokenChange}
                >
                  {settings[1].options.map((option) => (
                    <MenuItem key={uuidv4()} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            ) : (
              'Null'
            )}
          </div>
        </div>
      </td>
      <td style={{ textAlign: 'center', paddingRight: '30px', lineHeight: 'normal' }}>{`${Math.floor(
        user.token_used / 1000
      )}k / ${user.token_limit !== null ? getToken(user.token_limit).label : 'null'}`}</td>
      <td>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {user.model !== null && user.model.length > group.default_model.length && (
            <div
              style={{
                marginLeft: '0px',
                height: '0px',
                marginTop: '10px',
              }}
            >
              <span
                style={{
                  color: `var(--red-clr)`,
                  marginTop: '10px',
                  fontSize: '32px',
                  marginLeft: '-20px',
                }}
              >
                *
              </span>
            </div>
          )}

          <div>
            {user.model !== null ? (
              <Select
                className="select-box-tool-box"
                size="medium"
                id={`${Math.floor(Math.random() * 25)}`}
                multiple
                value={getModelNameByValue(user.model)}
                renderValue={(selected) => selected.join(', ')}
                label="Model"
                name="model"
                onChange={handleModelChange}
              >
                {settings[0].options.map((option) => (
                  <MenuItem key={uuidv4()} value={option.value}>
                    <Checkbox checked={getModelNameByValue(user.model).includes(option.value)} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            ) : (
              'Null'
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
