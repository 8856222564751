import axios from '@/configs/axios.js';

let serviceInstance = null;
class FileSystemService {
    async uploadFileToBlob({ mediaKey, file, type, metadata }) {
        try {
            // const uploadType = type || 'data'
            const formData = new FormData();
            formData.append('file', file);
            formData.append('mediaKey', mediaKey);
            // formData.append('uploadType', uploadType);
            formData.append('metadata', JSON.stringify(metadata));

            const response = await axios.post('/api/v1/storage/playground/add-file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('File upload successfully:', response);

            return response ? true : false;
        } catch (err) {
            console.error('Error uploading file to blob:', err);
            return null;
        }
    }
}

export default function createFileSystemService() {
    if (!serviceInstance) {
        console.log(' Creating new instance of fileSystemService');
        serviceInstance = new FileSystemService();
    }
    return serviceInstance;
}
