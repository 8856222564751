export function markFilesList(files, subfolders, docIds) {
    // Mark the files at the top level
    // console.log(files,subfolders)
    const newFiles = files.map(file => ({
        ...file,
        marked: docIds.includes(file.doc_id) ? true : file.marked
    }));

    // Function to mark files in subfolders
    function markFilesInFolder(folder) {
        const newFolderFiles = folder.files.map(file => ({
            ...file,
            marked: docIds.includes(file.doc_id) ? true : file.marked
        }));
        const newSubfolders = folder.subfolders.map(subfolder => markFilesInFolder(subfolder));
        return { ...folder, files: newFolderFiles, subfolders: newSubfolders };
    }

    // Mark files in subfolders
    const newSubfolders = subfolders.map(subfolder => markFilesInFolder(subfolder));

    return { files: newFiles, subfolders: newSubfolders };
}
