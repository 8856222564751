import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAtom } from 'jotai';
import createAuthProviderService from '@/services/authProviderService';
import { finishedLoginAtom } from '@/api/users';
import { loginConfigAtom } from '@/atoms/general';
import { useOktaAuth } from '@okta/okta-react';

export const OktaProvider = ({ handleLogin, loginUserWithData }) => {
  const [config] = useAtom(loginConfigAtom);
  const navigate = useHistory();

  const authProviderService = createAuthProviderService();
  const provider = authProviderService.createProvider(config.type, config.clientPayload);
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    const handleLoginProvider = async () => {
      console.log('handle login provider');

      try {
        await provider.initialize();

        if (!authState || !oktaAuth) {
          return <div>There was an auth issue...</div>;
        }

        if (!authState.isAuthenticated) {
          return <div>Not Logged in yet</div>;
        }

        const loginResponse = await provider.login(authState);
        if (loginResponse.isSuccess) {

          // Login to jeen and create jeen token
          const isLogin = await handleLogin({
            email: loginResponse.userMail.toLowerCase(),
            providerToken: loginResponse.providerToken,
          });
          if (isLogin) {
            loginUserWithData();
          }
        } else {
          localStorage.clear();
          // navigate.push('/');
        }
      } catch (err) {
        console.log(err);
        // navigate.push('/');
      }
    };
    handleLoginProvider();
  }, []);

  return <></>;
};
