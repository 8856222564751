import axios from '@/configs/axios.js';
import { BlockBlobClient } from '@azure/storage-blob';
import { UPLOAD_PROGRESS_EVENT } from '../services/fileQueueService';
import { ALL_SUPPORTED_FILE_EXTENSIONS, getVectorMethodSupportedTypes, mapVectorMethodDisplayNameToApiName } from '../utils/vectorMethods';

export const getOngoingDocumentProcessing = async () => {
    try {
        const res = await axios.post(`/api/v1/doc/processing-tasks`);
        if (res.status !== 200) {
            throw new Error(`Error getting ongoing document processing, got: ${res.status}`);
        }
        return res.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}


export const startDocumentProcessing = async ({
    mediaKey,
    metadata,
    content,
    userId,
    docDescription,
    docTitle,
    chunkSizeFromUser,
    overlapSizeFromUser,
    completionModel,
    embeddingModel,
    vectorMethods,
    vectorLanguage,
    folderId,
    pathToDocument,
    isQuestionGenerator,
    sync,
}) => {
    try {
        const res = await axios.post('/api/v1/doc/start-processing', {
            mediaKey: mediaKey,
            metadata: metadata,
            userId: userId,
            docDescription: docDescription,
            docTitle: docTitle,
            chunkSizeFromUser: chunkSizeFromUser,
            overlapSizeFromUser: overlapSizeFromUser,
            apiModel: completionModel,
            embeddingModel: embeddingModel,
            vectorMethods: vectorMethods,
            vectorLanguage: vectorLanguage,
            folderId: folderId,
            pathToDocument: pathToDocument,
            isQuestionGenerator: isQuestionGenerator
        });
        if (res.status !== 200) {
            throw new Error(`Error sending file processing, got: ${res.status}`);
        }
        return res.data;
    } catch (err) {
        console.error('Error sending file processing:', err);
        return null
    }
};

function unsupportedFileByVectorMethodError(fileExtension, vectorMethod) {
    alert(`File extension ${fileExtension} not supported by vector method ${vectorMethod}`);
    console.error('File extension not supported:', fileExtension);
    return false;
}

export function filesSupportedByVectorMethods(files, vectorMethods) {
    const vectorMethodsSupportedTypes = vectorMethods.map(vectorMethod => {
        return { vectorMethod, supportedTypes: getVectorMethodSupportedTypes(vectorMethod) };
    });

    for (const file of files) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        console.log()
        for(const item of vectorMethodsSupportedTypes) {
            const { supportedTypes, vectorMethod } = item;
            console.log({ vectorMethod, fileExtension, supportedTypes });
            if (!supportedTypes.includes(fileExtension)) {
                return unsupportedFileByVectorMethodError(fileExtension, vectorMethod);
            }
            console.log(`File: ${file.name} supported by vector method: ${vectorMethod}`);
        }

    }
    console.log(`All files supported by vector methods: ${files.map(file => file.name).join(', ')}`);
    return true;
}

export function getFileTypeByExtension(extension) {
    console.log({ extension });
    switch (extension) {
        case ALL_SUPPORTED_FILE_EXTENSIONS.DOC:
            return 'application/msword'
        case ALL_SUPPORTED_FILE_EXTENSIONS.DOCX:
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case ALL_SUPPORTED_FILE_EXTENSIONS.PDF:
            return 'application/pdf';
        case ALL_SUPPORTED_FILE_EXTENSIONS.CSV:
            return 'text/csv';
        case ALL_SUPPORTED_FILE_EXTENSIONS.XLSX:
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case ALL_SUPPORTED_FILE_EXTENSIONS.JSON:
            return 'application/json';
        case ALL_SUPPORTED_FILE_EXTENSIONS.TXT:
            return 'text/plain';
        default:
            return 'application/octet-stream';
    }
}
export const uploadFileToBlob = async ({ content, file, fileToken, mediaKey, blobPath, metadata }) => {
    try {
        const blobUrl = `${blobPath}/${mediaKey}?${fileToken}`;

        const blockBlobClient = new BlockBlobClient(blobUrl);

        const fileExtension = file.name.split('.').pop().toLowerCase();

        const blobContentType = getFileTypeByExtension(fileExtension);
        const parseMetadataForAzure = (obj, prefix = '') => {
            const delimeter = "_"
            return Object.entries(obj).reduce((acc, [key, value]) => {
            const newKey = prefix ? `${prefix}${delimeter}${key}` : key;
            if (typeof value === 'object' && value !== null) {
                Object.assign(acc, parseMetadataForAzure(value, newKey));
            } else {
                if (/[\u0080-\uFFFF]/.test(value)) {
                    console.warn(`Non-ASCII character found in metadata value: ${value}`);
                    value = encodeURIComponent(value);
                }
                acc[newKey] = String(value);
            }
            return acc;
            }, {});
        };

        const blobMetadata = parseMetadataForAzure(metadata);
        console.log({ blobMetadata });
        const uploadOptions = {
            blobHTTPHeaders: { blobContentType: blobContentType },
            blockSize: 1 * 1024 * 1024, // 4MB block size
            onProgress: (progress) => {
                console.log({ progress });
                console.log(`File size ${file.size}, uploaded ${progress.loadedBytes}`);
                const percentCompleted = Math.round((progress.loadedBytes / file.size) * 100);
                console.log({ percentCompleted });
                dispatchEvent(new CustomEvent(UPLOAD_PROGRESS_EVENT, { detail: { resourceId: mediaKey, progress: percentCompleted } }))
            },
            metadata: blobMetadata,
        };

        const response = await blockBlobClient.uploadData(content, uploadOptions);
        return response;
    } catch (err) {
        console.error('Error uploading file to blob:', err);
        return null
    }
};


export const uploadFileToLocalFolder = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const res = await axios.post('/api/v1/storage/playground/file-system', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log('response', res);

    if (res.status !== 200) {
      throw new Error(`Error save local file, got: ${res.status}`);
    }
    const { token, mediaKey, fileType } = res.data;
    return {
      fileToken: token,
      mediaKey,
      fileType,
    };
  } catch (error) {
    console.log(error);
  }
};
