import createAzureStorageService from './azureStorageService';
import createFileSystemService from './fileSystemService';

let serviceInstance = null;
class storageFactoryService {
  createStorage(type) {
    if (type === 'azure_blob') {
      return new createAzureStorageService();
    } else if (type === 'file_system') {
      return new createFileSystemService();
    } else {
      throw new Error(`Unsupported provider type: ${type}`);
    }
  }
}

export default function createStorageFactoryService() {
  if (!serviceInstance) {
    console.log(' Creating new instance of storageFactoryService');
    serviceInstance = new storageFactoryService();
  }
  return serviceInstance;
}
