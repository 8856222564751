import './admin-table.scoped.css';
import React, { useState } from 'react';
import ExpandableTable from '@/components/ExpandableTable/ExpandableTable';
import TableHeaderSort from '@/components/TableHeaderSort/TableHeaderSort';
import moment from 'moment';
import { useAtom } from 'jotai';
import { modelsGettersAtom } from '@/atoms/models';

export function AdminTable({ groups, settings }) {
  const [modelsGetters] = useAtom(modelsGettersAtom);
  const [expandedGroupId, setExpandedGroupId] = useState(null);
  const [sortOrder, setSortOrder] = useState(1);
  const dropdownOptions = modelsGetters.getDropdown('completion', 'chat');

  function handleSort(arr, key) {
    arr.sort((a, b) => {
      if (key === 'last_updated') {
        const dateA = moment(a[key], 'DD MMM, YYYY').valueOf();
        const dateB = moment(b[key], 'DD MMM, YYYY').valueOf();
        return (dateA - dateB) * sortOrder;
      } else {
        const valA = a[key].toLowerCase();
        const valB = b[key].toLowerCase();
        return (valA > valB ? 1 : valA < valB ? -1 : 0) * sortOrder;
      }
    });
    setSortOrder(-sortOrder);
  }

  const settingsArr = [
    {
      name: 'model',
      options: [...dropdownOptions],
    },
    {
      name: 'token',
      options: [
        { label: '100K', value: 100000, id: 1 },
        { label: '150K', value: 150000, id: 2 },
        { label: '200K', value: 200000, id: 3 },
        { label: '250K', value: 250000, id: 4 },
        { label: '300K', value: 300000, id: 5 },
        { label: '350K', value: 350000, id: 6 },
        { label: '400K', value: 400000, id: 7 },
        { label: '450K', value: 450000, id: 8 },
        { label: '500K', value: 500000, id: 9 },
        { label: '1M', value: 1000000, id: 10 },
      ],
    },
  ];

  return (
    <>
      <div className="table-container">
        <table>
          <thead
            style={{
              backgroundColor: 'var(--chat-background-clr)',
              position: 'sticky',
              
              top: 0,
              zIndex: 2,
            }}
          >
            <tr>
              {settings[0].options.map((header, index) => {
                return (
                  <TableHeaderSort
                    key={index}
                    type={header.type}
                    value={header.type === 'header' ? header.value : ''}
                    style={header.style}
                    handler={
                      header.type === 'header'
                        ? handleSort
                        : {
                            value: false,
                            // func: handleMarkAllChange,
                          }
                    }
                    arr={groups}
                    isSelect={header.isSelect}
                    table={settings[1].type}
                    isSort={header.isSort}
                  />
                );
              })}
            </tr>
          </thead>

          <tbody>
            {groups.length > 0 &&
              groups.map((group, index) => {
                return (
                  group && (
                    <ExpandableTable
                      key={group.id || index}
                      group={group}
                      settings={settingsArr}
                      isExpanded={expandedGroupId === group.group_id}
                      onExpand={() =>
                        setExpandedGroupId(expandedGroupId === group.group_id ? null : group.group_id)
                      }
                    />
                  )
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
