import axios from '@/configs/axios.js';

export async function updateGroupUsersToken(data) {
  try {
    const { groupId, token } = data;

    const res = await axios.post(`/api/groups/users/${groupId}/token`, {
      token,
    });

    if (res.status !== 200) {
      return false;
    }

    return true;
  } catch (error) {
    console.log(error);
  }
}

export async function updateGroupUsersModel(data) {
  try {
    const { groupId, model } = data;

    const res = await axios.post(`/api/groups/users/${groupId}/model`, {
      model: model !== null ? JSON.stringify(model) : model,
    });

    if (res.status !== 200) {
      return false;
    }

    return true;
  } catch (error) {}
}

export async function updateGroupToken(data) {
  try {
    const { groupId, token } = data;

    const res = await axios.post(`/api/groups/group/${groupId}/token`, {
      token,
    });

    if (res.status !== 200) {
      return false;
    }

    return true;
  } catch (error) {
    console.log(error);
  }
}

export async function updateGroupModel(data) {
  try {
    const { groupId, model } = data;

    const res = await axios.post(`/api/groups/group/${groupId}/model`, {
      model: JSON.stringify(model),
    });

    if (res.status !== 200) {
      return false;
    }

    return true;
  } catch (error) {
    console.log(error);
  }
}

export async function updateUserToken(data) {
  try {
    const { userId, token } = data;

    const res = await axios.post(`/api/groups/user/${userId}/token`, {
      token,
    });

    if (res.status !== 200) {
      return false;
    }

    return true;
  } catch (error) {
    console.log(error);
  }
}

export async function updateUserModel(data) {
  try {
    const { userId, model } = data;

    const res = await axios.post(`/api/groups/user/${userId}/model`, {
      model: JSON.stringify(model),
    });

    if (res.status !== 200) {
      return false;
    }

    return true;
  } catch (error) {
    console.log(error);
  }
}

export async function getGroupsUsers() {
  try {
    const result = await axios.get('/api/groups/users');

    return result.data;
  } catch (error) {
    console.log(error);
  }
}
